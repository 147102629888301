import apiInitializer from "../common/apiInitializer";

let defaultName = "";
let defaultDirection = "asc";
let defaultPage = 1;
let defaultSize = 10;
let headers: {[key: string]: any} = {
  "name": defaultName,
  "direction": defaultDirection,
  "page": defaultPage,
  "size": defaultSize
};
let recordCount = 0;
function setColumnName(columnName: string){
  headers["name"] = columnName;
}
function setSortDirection(sortDirection: string){
  headers["direction"] = sortDirection;
}
function setPageNumber(pageNumber: number){
  headers["page"] = pageNumber;
}
function setPageSize(pageSize: number){
  headers["size"] = pageSize;
}
function getPageNumber(){
  return headers["page"];
}
function getPageSize(){
  return headers["size"];
}
function setRecordCount(headerValue: string){
  var i: number = +headerValue;
  if (i > 0){
    recordCount = i;
  }
}
function getRecordCount(){
  return recordCount;
}

async function getApiCall(
  path: string) {
  const response = await apiInitializer
    .get(path, { headers: 
      {
        'column-sort-page-id': headers["page"],
        'column-sort-page-size': headers["size"],
        'column-sort-name-header': headers["name"],
        'column-sort-direction-header': headers["direction"]
      }})
    .then((response) => {
      setColumnName(defaultName);
      setSortDirection(defaultDirection);
      setPageNumber(defaultPage);
      // setPageSize(defaultSize); don't reset this, it might be good to maintain page size state between pages
      setRecordCount(response.headers["record-count"]);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return response;
}

async function putApiCall(
  path: string, 
  putData: any) {
  const response = await apiInitializer
    .put(path, putData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return response;
}

async function postApiCall(
  path: string, 
  postData: any) {
  console.log("POST: " + path, postData);
  const response = await apiInitializer
    .post(path, postData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return response;
}

async function deleteApiCall(
  path: string) {
  console.log("DELETE: " + path);
  const response = await apiInitializer
    .delete(path)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return response;
}

async function getDownloadCall(
  path: string) {
  console.log("GET: " + path);
  const response = await apiInitializer
    .get(path, { responseType: 'blob' })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  return response;
}

const commonApi = { 
    getApiCall,
    putApiCall,
    postApiCall,
    deleteApiCall,
    setColumnName,
    setSortDirection,
    setPageNumber,
    setPageSize,
    getPageNumber,
    getPageSize,
    getRecordCount,
    getDownloadCall
};
export default commonApi;