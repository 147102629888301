import {
  Box,
  SimpleGrid,
  TextInput,
  NativeSelect,
  Group,
  Button,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import commonApi from "../../api/commonApi";
import OptionItem from "../../types/common/optionItem";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";

export default function PurchaseOrderLineForm({
  purchaseOrder,
  setPurchaseOrder,
  lineItems,
  setLineItems,
  lineItemToEdit,
  setLineItemToEdit,
  lineItemNo,
  setLineItemNo,
  updatedlineItems,
  setUpdatedLineItems,
}: {
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>;
  lineItems: PurchaseOrderLineItem[];
  setLineItems: Dispatch<SetStateAction<PurchaseOrderLineItem[]>>;
  lineItemToEdit?: PurchaseOrderLineItem;
  setLineItemToEdit?: Dispatch<SetStateAction<PurchaseOrderLineItem>>;
  lineItemNo?: number;
  setLineItemNo?: Dispatch<SetStateAction<number>>;
  updatedlineItems?: PurchaseOrderLineItem[];
  setUpdatedLineItems?: Dispatch<SetStateAction<PurchaseOrderLineItem[]>>;
}) {
  const [unitOfMeasures, setUnitOfMeasures] = useState<OptionItem[]>([]);
  const [items, setItems] = useState<OptionItem[]>([]);
  const [sites, setSites] = useState<OptionItem[]>([]);
  const [params, setParams] = useState({});
  const [itemId, setItemId] = useState("");

  const getUnitOfMeasures = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/inventory/unitofmeasures");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setUnitOfMeasures(data2);
    }
  };

  const getSites = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/inventory/sites");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setSites(data2);
    }
  };

  const getItemList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall(
        "/inventory/items?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setItems(data2);
    }
  };

  const getItemById = async () => {
    try {
      let data = await commonApi.getApiCall("/inventory/items/" + itemId);
      lineItemForm.setFieldValue("lineNumber", lineItemNo ? lineItemNo : 0);
      lineItemForm.setFieldValue("itemID", parseInt(itemId));
      lineItemForm.setFieldValue(
        "unitOfMeasureID",
        parseInt(data.unitOfMeasureID)
      );
      lineItemForm.setFieldValue("unitPrice", data.listCost);
      lineItemForm.setFieldValue("itemDescription", data.description);
      lineItemForm.setFieldValue("itemName", data.name);
    } catch (error) {}
  };

  const submitHandler = async (values: PurchaseOrderLineItem) => {
    let quantity = !values.quantity ? 0 : values.quantity;
    let unitPrice = !values.unitPrice ? 0 : values.unitPrice;
    let extendedCost = quantity * unitPrice;
    values.lineTotal = Math.round(extendedCost * 100) / 100;
    values.shipMethodID = 1;

    if (updatedlineItems) {
      let filteredItems: PurchaseOrderLineItem[] = lineItems.filter(
        (x: PurchaseOrderLineItem) => x.itemID != values.itemID
      );
      filteredItems.push(values);
      if (setUpdatedLineItems) {
        updatedlineItems = filteredItems;
        setUpdatedLineItems(filteredItems);
      }
      if (setLineItemNo) {
        let newNo = 0;
        if (lineItemNo) newNo = lineItemNo + 1;
        setLineItemNo(newNo);
      }

      purchaseOrder.purchaseOrderLines = updatedlineItems;
      let poTotal = 0;
      updatedlineItems.forEach((x) => {
        let lineTotal: number = x.lineTotal ? x.lineTotal : 0;
        poTotal = poTotal + lineTotal;
      });
      purchaseOrder.total = parseInt(
        (Math.round(poTotal * 100) / 100).toFixed(2)
      );
      purchaseOrder.subTotal = parseInt(
        (Math.round(poTotal * 100) / 100).toFixed(2)
      );
    } else {
      let filteredItems: PurchaseOrderLineItem[] = lineItems.filter(
        (x: PurchaseOrderLineItem) => x.itemID != values.itemID
      );
      filteredItems.push(values);
      lineItems = filteredItems;
      setLineItems(lineItems);
      if (setLineItemNo) {
        let newNo = 0;
        if (lineItemNo) newNo = lineItemNo + 1;
        setLineItemNo(newNo);
      }

      //update amount on PO object
      purchaseOrder.purchaseOrderLines = lineItems;
      let poTotal = 0;
      lineItems.forEach((x) => {
        let lineTotal: number = x.lineTotal ? x.lineTotal : 0;
        poTotal = poTotal + lineTotal;
      });
      purchaseOrder.total = parseInt(
        (Math.round(poTotal * 100) / 100).toFixed(2)
      );
      purchaseOrder.subTotal = parseInt(
        (Math.round(poTotal * 100) / 100).toFixed(2)
      );
    }

    //get other amounts
    purchaseOrder.total =
      purchaseOrder.total +
      (purchaseOrder.freight ? purchaseOrder.freight : 0) +
      (purchaseOrder.tradeDiscount ? purchaseOrder.tradeDiscount : 0) +
      (purchaseOrder.taxAmount ? purchaseOrder.taxAmount : 0) +
      (purchaseOrder.misc ? purchaseOrder.misc : 0);

    purchaseOrder.total = parseInt(
      (Math.round(purchaseOrder.total * 100) / 100).toFixed(2)
    );

    setPurchaseOrder(purchaseOrder);
    lineItemForm.reset();
  };

  useEffect(() => {
    getUnitOfMeasures();
    getSites();
    getItemList();
    if (itemId != "") getItemById();

    if (setLineItemNo) setLineItemNo(lineItems.length + 1);
  }, [itemId]);

  useEffect(() => {
    if (lineItemToEdit)
      if (lineItemToEdit.itemID) {
        lineItemForm.setValues({
          lineNumber: lineItemToEdit?.lineNumber,
          itemID: lineItemToEdit?.itemID,
          unitOfMeasureID: lineItemToEdit?.unitOfMeasureID,
          quantity: lineItemToEdit?.quantity,
          itemDescription: lineItemToEdit?.itemDescription,
          itemName: lineItemToEdit?.itemName,
          siteID: lineItemToEdit?.siteID,
          unitPrice: lineItemToEdit?.unitPrice,
          quantityCancelled: lineItemToEdit?.quantityCancelled,
          lineTotal: lineItemToEdit?.lineTotal,
          lineSubTotal: lineItemToEdit?.lineSubTotal,
          purchaserOrderID: lineItemToEdit?.purchaseOrderId,
          purchaserOrderLineID: lineItemToEdit?.purchaseOrderID,
        });
      }
  }, [lineItemToEdit]);

  const lineItemForm = useForm({
    initialValues: {
      lineNumber: 0,
      itemID: 0,
      unitOfMeasureID: 0,
      quantity: 0,
      itemDescription: "",
      itemName: "",
      siteID: 0,
      unitPrice: 0,
      quantityCancelled: 0,
      lineTotal: 0,
      lineSubTotal: 0,
      purchaserOrderID: 0,
      purchaserOrderLineID: 0,
    },
    validate: {
      itemID: (value) =>
        value === 0 || value === null ? "Please select an item" : null,
      quantity: (value) =>
        value === 0 || value === null ? "Please add quantity to order" : null,
      siteID: (value) =>
        value === 0 || value === null ? "Please select site" : null,
      unitOfMeasureID: (value) =>
        value === 0 || value === null ? "Please select site" : null,
    },
  });

  return (
    <Box>
      <form
        onSubmit={lineItemForm.onSubmit((values) => {
          let validationResult = lineItemForm.validate();
          if (validationResult.hasErrors) {
            lineItemForm.setErrors(validationResult.errors);
          } else {
            submitHandler(values);
          }
        })}
      >
        <SimpleGrid cols={2}>
          <Box>
            <TextInput
              label="Line Number"
              placeholder="Enter line number"
              withAsterisk
              disabled
              {...lineItemForm.getInputProps("lineNumber")}
            />
            <NativeSelect
              label="Item"
              withAsterisk
              data={items}
              {...lineItemForm.getInputProps("itemID")}
              onChange={(event) => {
                setItemId(event.currentTarget.value);
              }}
            />
            <NativeSelect
              label="Unif of Measure"
              withAsterisk
              data={unitOfMeasures}
              disabled
              {...lineItemForm.getInputProps("unitOfMeasureID")}
            />
            <NumberInput
              label="Quantity Ordered"
              placeholder="Enter quantity"
              allowNegative={false}
              {...lineItemForm.getInputProps("quantity")}
            />
          </Box>
          <Box>
            <TextInput
              label="Description"
              placeholder="Enter description"
              withAsterisk
              disabled
              {...lineItemForm.getInputProps("itemDescription")}
            />
            <NativeSelect
              label="Site ID"
              withAsterisk
              data={sites}
              {...lineItemForm.getInputProps("siteID")}
            />
            <TextInput
              label="Unit Cost"
              placeholder="Enter unit cost"
              withAsterisk
              disabled
              {...lineItemForm.getInputProps("unitPrice")}
            />
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  lineItemForm.reset();
                }}
              >
                Clear
              </Button>
              <Button type="submit">Add line item</Button>
            </Group>
          </Box>
        </SimpleGrid>
      </form>
    </Box>
  );
}
