import { TextInput } from "@mantine/core";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";
import { CustomFormProvider, useCustomForm } from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";

function SubLedgerTypeActionMapCompanySearch(props: any) {
  const form = useCustomForm({
    initialValues: {
      search: "",
      subLedgerTypeID: "0",
      accountID: "0",
      UnMappedOnly: "0",
    },
  });
  return (
    <CustomFormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
        <SearchSection
          buttonSection={
            <ButtonGroup
              cancelLabel="Reset"
              cancelClick={form.reset}
              okLabel="Search"
            />
          }
        >
          <TextInput
            label="Search"
            placeholder="search by action or sub ledger type"
            {...form.getInputProps("search")}
          />
          <DropdownInput
              apiURL={"/system/subledgertypes"}
              name={"subLedgerTypeID"}
              label={"Sub Ledger Type"}
          />             
          <DropdownInput
              apiURL={"/accounts"}
              name={"accountID"}
              label={"Account"}
          />          
        </SearchSection>
      </form>
    </CustomFormProvider>
  );
}
export default SubLedgerTypeActionMapCompanySearch;