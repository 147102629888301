import { Box, Stepper, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import Return from "../../../types/inventory/return";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import alerts from "../../common/alerts";
import ReturnsAddForm from "./returnsAddForm";
import ReturnLine from "../../../types/inventory/returnLine";
import ReturnItemsList from "./returnItemList";
import dayjs from "dayjs";

export default function ReturnEdit() {
  const navigate = useNavigate();
  const { returnID } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [lineItems, setLineItems] = useState<ReturnLine[]>([]);
  const [returns, setReturns] = useState<Return>({
    returnID: 0,
    docNumber: "",
    creditorReferenceNumber: "",
    docDate: "",
    postingDate: "",
    createdDate: "",
    updatedDate: "",
    returnTypeID: 1,
    returnStatusID: 1,
    purchaseOrderID: 0,
    batchID: 0,
    subLedgerTypeID: 0,
    companyID: 0,
    createdByID: 0,
    codifiedByID: 0,
    returnTypeName: "",
    returnStatusName: "",
    purchaseOrderDocNumber: "",
    systemSubLedgerTypeName: "",
    returnLines: [],
  });

  const form = useForm({
    initialValues: {
      typeID: 0,
      returnNumber: "",
      currencyName: "",
      currencyID: 0,
      creditorID: 0,
      creditorName: "",
      creditorDocNumber: "",
      batchID: 0,
      docDate: "",
      postingDate: "",
      createdDate: "",
      updatedDate: "",
      purchaseOrderDocNumber: "",
      purchaseOrderID: 0,
      returnTypeID: 0,
      returnTypeName: "",
    },
    validate: {
      returnTypeID: (value) =>
        value === 0 ? "Please select return type" : null,
      purchaseOrderID: (value) =>
        value === 0 ? "Please select purchase order" : null,
      postingDate: (value) =>
        value === "" ? "Please select return date" : null,
    },
  });

  const getReturnById = async () => {
    try {
      let data = await commonApi.getApiCall("purchasing/returns/" + returnID);
      form.setValues(data);
      form.setFieldValue("currencyName", data.purchaseOrder.currencyName);
      form.setFieldValue("creditorName", data.purchaseOrder.creditorName);
      form.setFieldValue("purchaseOrderID", data.purchaseOrder.purchaseOrderID);

      data.returnLines.forEach((x: ReturnLine) => {
        x.quantityReceived = x.receiptLine ? x.receiptLine.quantityReceived : 0;
        x.purchaseOrderDocNumber = x.receipt
          ? x.receipt.purchaseOrderDocNumber
          : "";
        x.itemDescription = x.receiptLine ? x.receiptLine.itemDescription : "";
      });

      setReturns(data);
      setLineItems(data.returnLines);
    } catch (error) {}
  };

  useEffect(() => {
    getReturnById();
  }, []);

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        let validationResult = form.validate();
        if (validationResult.hasErrors) {
          form.setErrors(validationResult.errors);
        } else {
          try {
            returns.postingDate = dayjs(
              form.getValues().postingDate,
              "DD/MM/YYYY"
            ).toISOString();

            commonApi
              .putApiCall("/purchasing/returns/" + returnID, returns)
              .then((response) => {
                alerts.showMessage("Return updated");
              });

            setCurrentStep(currentStep + 1);
          } catch (error) {
            alerts.showError(error);
          }
        }
      }
    }

    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const updateReturnHandler = () => {
    returns.postingDate = dayjs(form.getValues().postingDate, "DD/MM/YYYY")
      .toDate()
      .toISOString();
    try {
      commonApi
        .putApiCall("/purchasing/returns/" + returnID, returns)
        .then((response) => {
          navigate("/Inventory/Returns/List");
          alerts.showMessage("Return updated");
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Returns">
            <ReturnsAddForm
              form={form}
              returns={returns}
              setReturn={setReturns}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              isUpdate={true}
            />
          </Stepper.Step>
          <Stepper.Step label="Enter Items">
            <ReturnItemsList returns={returns} setReturn={setReturns} />
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <ReturnsAddForm
              form={form}
              returns={returns}
              setReturn={setReturns}
              isLastStep={true}
            />
            <ReturnItemsList
              returns={returns}
              setReturn={setReturns}
              isLastStep={true}
            />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 3}>
              Next
            </Button>
          ) : (
            <Button onClick={updateReturnHandler}>Update</Button>
          )}

          <Button onClick={() => navigate("/Inventory/Returns/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
