import { Box, Button, Group, Stepper } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReturnsAddForm from "./returnsAddForm";
import Return from "../../../types/inventory/return";
import { useForm } from "@mantine/form";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import ReturnItemsList from "./returnItemList";

export default function ReturnsAdd() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [returns, setReturns] = useState<Return>({
    returnID: 0,
    docNumber: "",
    creditorReferenceNumber: "",
    docDate: "",
    postingDate: "",
    createdDate: "",
    updatedDate: "",
    returnTypeID: 1,
    returnStatusID: 1,
    purchaseOrderID: 0,
    batchID: 0,
    subLedgerTypeID: 0,
    companyID: 0,
    createdByID: 0,
    codifiedByID: 0,
    returnTypeName: "",
    returnStatusName: "",
    purchaseOrderDocNumber: "",
    systemSubLedgerTypeName: "",
    returnLines: [],
  });

  const form = useForm({
    initialValues: {
      typeID: 0,
      returnNumber: "",
      currencyName: "",
      currencyID: 0,
      creditorID: 0,
      creditorName: "",
      creditorDocNumber: "",
      batchID: 0,
      docDate: "",
      postingDate: "",
      createdDate: "",
      updatedDate: "",
      purchaseOrderDocNumber: "",
      purchaseOrderID: 0,
      returnTypeID: 0,
      returnTypeName: "",
    },
    validate: {
      returnTypeID: (value: number) =>
        value === 0 || value === null ? "Please select return type" : null,
      purchaseOrderID: (value: number) =>
        value === 0 ? "Please select purchase order" : null,
      postingDate: (value) =>
        value === "" ? "Please select return date" : null,
      creditorName: (value: string) =>
        value === "" ? "Please select creditor" : null,
      currencyName: (value: string) =>
        value === "" ? "Please select currency" : null,
    },
  });

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        let validationResult = form.validate();
        if (validationResult.hasErrors) {
          form.setErrors(validationResult.errors);
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    }

    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const addReturnHandler = () => {
    try {
      commonApi.postApiCall("/purchasing/returns", returns).then((response) => {
        navigate("/Inventory/Returns/List");
        alerts.showMessage("Return created");
      });

      setCurrentStep(currentStep + 1);
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Returns">
            <ReturnsAddForm
              form={form}
              returns={returns}
              setReturn={setReturns}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              isUpdate={false}
            />
          </Stepper.Step>
          <Stepper.Step label="Enter Items">
            <ReturnItemsList returns={returns} setReturn={setReturns} />
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <ReturnsAddForm
              form={form}
              returns={returns}
              setReturn={setReturns}
              isLastStep={true}
            />
            <ReturnItemsList
              returns={returns}
              setReturn={setReturns}
              isLastStep={true}
            />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 3}>
              Next
            </Button>
          ) : (
            <Button onClick={addReturnHandler}>Save</Button>
          )}

          <Button onClick={() => navigate("/Inventory/Returns/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
