import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import SearchSection from "../common/Sections/Search/SearchSection";
import ButtonGroup from "../common/ButtonGroup";
import { DateInput } from "@mantine/dates";

export default function PurchaseOrderSearch(props: any) {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const form = useForm({
    initialValues: {
      createdFromDate: "",
      createdToDate: "",
      search: "",
    },
  });
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        let fromDateStr = fromDate ? fromDate.toDateString() : "";
        let toDateStr = toDate ? toDate.toDateString() : "";
        values.createdFromDate = fromDateStr;
        values.createdToDate = toDateStr;
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Search"
          placeholder="search by PO number, creditor, buyer, currency etc.."
          {...form.getInputProps("search")}
        />
        <DateInput
          value={fromDate}
          onChange={setFromDate}
          label="Created from date"
          placeholder="from date"
        />
        <DateInput
          value={toDate}
          onChange={setToDate}
          label="Created to date"
          placeholder="to date"
        />
      </SearchSection>
    </form>
  );
}
