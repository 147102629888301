import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import commonApi from "../../../api/commonApi";
import { Box, TextInput, Group, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import alerts from "../../common/alerts";
import ReadOnly from "../../common/readOnly";
import Label from "../../common/label";
import { CustomFormProvider, useCustomForm } from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";
import SubLedgerTypeActionMapCompanyView from "../../../types/generalLedger/subLedgerTypeActionMapCompanyView";

export default function SubLedgerTypeActionMapCompanyFormEdit(
    { 
        systemSubLedgerTypeID, 
        systemSubLedgerTypeActionID, 
        setState 
    }: 
    { 
        systemSubLedgerTypeID: number, 
        systemSubLedgerTypeActionID: number, 
        setState: Dispatch<SetStateAction<number>> 
    }) {
    const [subLedgerTypeActionMapCompanyView, setSubLedgerTypeActionMapCompanyView] = useState<SubLedgerTypeActionMapCompanyView>({} as SubLedgerTypeActionMapCompanyView);
    const form = useCustomForm({
        initialValues: {
            companyID: 0,
            systemSubLedgerTypeID: 0,
            systemSubLedgerTypeName: "",
            systemSubLedgerTypeActionID: 0,
            systemSubLedgerTypeActionName: "",
            accountID: 0,
            accountName: ""
        }        
      });      
    const getSubLedgerTypeActionMapCompanyViewById = async () => {
      try {
        let data = await commonApi.getApiCall("/SubLedgerType/ActionMap/CompanyView?subLedgerTypeID=" + systemSubLedgerTypeID + "&subLedgerTypeActionID=" + systemSubLedgerTypeActionID);
        setSubLedgerTypeActionMapCompanyView(data);
        form.setValues({
          companyID: data.companyID,
          systemSubLedgerTypeID: data.systemSubLedgerTypeID,
          systemSubLedgerTypeName: data.systemSubLedgerTypeName,
          systemSubLedgerTypeActionID: data.systemSubLedgerTypeActionID,
          systemSubLedgerTypeActionName: data.systemSubLedgerTypeActionName,
          accountID: data.accountID,
          accountName: data.accountName});   
      } catch (error) {}
    };    
    useEffect(() => {      
        getSubLedgerTypeActionMapCompanyViewById();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
        try {
            await commonApi.putApiCall("/SubLedgerType/ActionMap/Company", values).then(() => {
                alerts.showMessage("Account Map updated");
                setState(Math.random());
                closeAllModals();
              });
        } 
        catch (error) {
          alerts.showError(error);
        }
      };      
    return (
        <>
          <Box mx="auto">
            <CustomFormProvider form={form}>
                <form
                    onSubmit={form.onSubmit((values) => {
                        onSubmitHandler(values);
                })}>
                    <Label name="Sub Ledger Type" />
                    <ReadOnly value={subLedgerTypeActionMapCompanyView.systemSubLedgerTypeName} />           
                    <Label name="Action" />
                    <ReadOnly value={subLedgerTypeActionMapCompanyView.systemSubLedgerTypeActionName} />                       
                    <DropdownInput
                        apiURL={"/accounts"}
                        name={"accountID"}
                        label={"Default Account (can be over-ridden by sub ledger " + subLedgerTypeActionMapCompanyView.systemSubLedgerTypeName + " type entities)"}
                    />   
                    <Group justify="flex-end" mt="md">
                        <Button
                        onClick={(event) => {
                            closeAllModals();
                        }}>
                        Back
                        </Button>
                        <Button type="submit">Update</Button>
                    </Group>
                </form>
            </CustomFormProvider>
          </Box>
        </>
      );
}