import { Box, Button, Group } from "@mantine/core";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import { Dispatch, SetStateAction, useState } from "react";
import commonApi from "../../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { IconMoodConfuzed } from "@tabler/icons-react";
import { closeAllModals } from "@mantine/modals";
import Return from "../../../types/inventory/return";
import ReturnLine from "../../../types/inventory/returnLine";
import ReceiptLine from "../../../types/inventory/receiptLine";

export default function ReceivePODetails({
  lineItems,
  returns,
  setReturns,
}: {
  lineItems: ReceiptLine[];
  returns?: Return;
  setReturns?: Dispatch<SetStateAction<Return>>;
}) {
  const [selectedRecords, setSelectedRecords] = useState<ReceiptLine[]>([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<ReceiptLine>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  return (
    <>
      <Box>
        <Box>
          <PaginationSection
            page={page}
            setPageFunction={setPage}
            pageSize={pageSize}
            setPageSizeFunction={setPageSize}
            recordCount={totalRecords}
          />
          <DataTable
            withTableBorder
            borderRadius="sm"
            height="50%"
            withColumnBorders
            striped
            highlightOnHover
            textSelectionDisabled
            minHeight={150}
            records={lineItems}
            noRecordsIcon={
              <Box p={4} mb={4}>
                <IconMoodConfuzed size={36} strokeWidth={1.5} />
              </Box>
            }
            noRecordsText="No records found"
            idAccessor="purchaseOrderLineID"
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            columns={[
              {
                accessor: "purchaseOrderDocNumber",
                title: "PO Number",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "receiptDocNumber",
                title: "Receipt Number",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "itemName",
                title: "Name",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "itemDescription",
                title: "Description",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "unitOfMeasureName",
                title: "Unif of Measure",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "siteName",
                title: "Site Name",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "quantityReceived",
                title: "Quantity Received",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "unitPrice",
                title: "Unit Cost",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
            ]}
          />

          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                closeAllModals();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();

                let returnLines: ReturnLine[] = [];
                if (lineItems) {
                  if (selectedRecords.length > 0) {
                    selectedRecords.forEach((item: ReceiptLine) => {
                      let returnLine: ReturnLine = {
                        returnLineID: 0,
                        purchaseOrderDocNumber: item.purchaseOrderDocNumber
                          ? item.purchaseOrderDocNumber
                          : "",
                        lineNumber: item.lineNumber ? item.lineNumber : 0,
                        quantityReturned: item.quantityReceived
                          ? item.quantityReceived
                          : 0,
                        quantityReceived: item.quantityReceived
                          ? item.quantityReceived
                          : 0,
                        unitPrice: item.unitPrice ? item.unitPrice : 0,
                        lineTotal: item.lineTotal ? item.lineTotal : 0,
                        returnID: 0,
                        returnDocNumber: "",
                        itemID: item.itemID ? item.itemID : 0,
                        itemName: item.itemName ? item.itemName : "",
                        itemDescription: item.itemDescription
                          ? item.itemDescription
                          : "",
                        siteID: item.siteID ? item.siteID : 0,
                        siteName: item.siteName ? item.siteName : "",
                        unitOfMeasureID: item.unitOfMeasureID
                          ? item.unitOfMeasureID
                          : 0,
                        unitOfMeasureName: item.unitOfMeasureName
                          ? item.unitOfMeasureName
                          : "",
                        trackingOptionID: 1,
                        receiptID: 0,
                        receiptLineID: 0,
                      };
                      returnLines.push(returnLine);
                    });
                  }
                }
                if (returns) {
                  returns.returnLines = returnLines;
                  if (setReturns) setReturns(returns);
                }
                closeAllModals();
              }}
            >
              Return
            </Button>
          </Group>
        </Box>
      </Box>
    </>
  );
}
