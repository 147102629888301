import {
  ActionIcon,
  Box,
  Group,
  NativeSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import OptionItem from "../../../types/common/optionItem";
import { DateInput, DateInputProps } from "@mantine/dates";
import Return from "../../../types/inventory/return";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import dayjs from "dayjs";
import commonApi from "../../../api/commonApi";
import PurchaseOrderLineItem from "../../../types/purchasing/purchaseOrderLineItem";
import { IconFile, IconPlus, IconSearch } from "@tabler/icons-react";
import { openModal } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import ReturnPODetails from "./returnPODetails";
import Receipt from "../../../types/inventory/receipt";
import ReceiptLine from "../../../types/inventory/receiptLine";
import ReturnLine from "../../../types/inventory/returnLine";

interface formValues {
  typeID: number;
  returnNumber: string;
  currencyName: string;
  currencyID: number;
  creditorID: number;
  creditorName: string;
  creditorDocNumber: string;
  batchID: number;
  docDate: string;
  createdDate: string;
  postingDate: string;
  updatedDate: string;
  purchaseOrderDocNumber: string;
  purchaseOrderID: number;
  returnTypeID: number;
  returnTypeName: string;
}

export default function ReturnsAddForm({
  form,
  returns,
  setReturn,
  purchaseOrder,
  setPurchaseOrder,
  isUpdate,
  isLastStep,
}: {
  form: UseFormReturnType<formValues>;
  returns?: Return;
  setReturn?: Dispatch<SetStateAction<Return>>;
  purchaseOrder?: PurchaseOrder;
  setPurchaseOrder?: Dispatch<SetStateAction<PurchaseOrder>>;
  isUpdate?: boolean;
  isLastStep?: boolean;
}) {
  const [postedDate, setPostedDate] = useState<Date | null>(null);
  const [poSelections, setPoSelections] = useState<OptionItem[]>([]);
  const [returnTypes, setReturnTypes] = useState<OptionItem[]>([]);
  const [selectedPOid, setSelectedPOid] = useState<string>("0");
  const [lineItems, setLineItems] = useState<ReceiptLine[]>([]);
  const [nextReturnID, setNextReturnID] = useState("");
  const [params, setParams] = useState({});

  const getPurchaseOrderList = async () => {
    let data: any[] = [];
    try {
      data = await commonApi.getApiCall(
        "/purchasing/purchaseorders?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setPoSelections(data2);
    }
  };

  const getReturnTypes = async () => {
    let data: any[] = [];
    try {
      data = await commonApi.getApiCall("/purchasing/returnTypes");
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setReturnTypes(data2);
    }
  };

  const dateParser: DateInputProps["dateParser"] = (input) => {
    if (input === "WW2") {
      return new Date(1939, 8, 1);
    }

    return dayjs(input, "DD/MM/YYYY").toDate();
  };

  const getPOById = async (poId: number) => {
    try {
      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/" + selectedPOid
      );

      if (data) {
        if (setPurchaseOrder) setPurchaseOrder(data);
        form.setFieldValue("currency", data.currencyName);
        form.setFieldValue("creditorName", data.creditorName);
        form.setFieldValue("currencyName", data.company.currency.name);
      }
    } catch (error) {}
  };

  const getReceiptLines = async (poId: number) => {
    try {
      let data: Receipt[] = await commonApi.getApiCall(
        "/purchasing/receipts/bypurchaseid/" + poId
      );

      let receiptLineItems: ReceiptLine[] = [];
      data.forEach((x) => {
        receiptLineItems.push(...x.receiptLines);
      });

      receiptLineItems.forEach((r) => {
        r.purchaseOrderDocNumber = data[0].purchaseOrderDocNumber;
      });

      setLineItems(receiptLineItems);
      if (isUpdate) showPODetails(receiptLineItems);

      if (returns) {
        if (data.length > 0) {
          returns.purchaseOrderID = data[0].purchaseOrderID
            ? data[0].purchaseOrderID
            : 0;
          returns.purchaseOrderDocNumber = data[0].purchaseOrderDocNumber
            ? data[0].purchaseOrderDocNumber
            : "";

          let returnLineItems: ReturnLine[] = [];
          receiptLineItems.forEach((x) => {
            let returnItem: ReturnLine = {
              quantityReturned: x.quantityReceived,
              unitPrice: x.unitPrice,
              returnLineID: 0,
              lineNumber: 0,
              quantityReceived: x.quantityReceived,
              lineTotal: x.lineTotal,
              returnID: 0,
              returnDocNumber: "",
              itemID: x.itemID,
              itemName: x.itemName,
              itemDescription: x.itemDescription,
              siteID: x.siteID,
              siteName: x.siteName,
              unitOfMeasureID: x.unitOfMeasureID,
              unitOfMeasureName: x.unitOfMeasureName,
              purchaseOrderDocNumber: returns.purchaseOrderDocNumber,
              trackingOptionID: x.trackingOptionID,
              receiptID: x.receiptID,
              receiptLineID: x.receiptLineID,
            };
            returnLineItems.push(returnItem);
          });

          returns.returnLines = returnLineItems;
          if (setReturn) setReturn(returns);
        }
      }
    } catch (error) {}
  };

  const showPODetails = (lineItems: ReceiptLine[]) => {
    openModal({
      modalId: "view",
      title: "View Details PO",
      size: "100vw",
      children: (
        <BrowserRouter>
          <ReturnPODetails
            lineItems={lineItems}
            returns={returns}
            setReturns={setReturn}
          />
        </BrowserRouter>
      ),
    });
  };

  const getNextReturnNumber = async () => {
    let data = null;

    try {
      data = await commonApi.getApiCall("/purchasing/returns/nextid");
      setNextReturnID(data);
      form.setFieldValue("returnNumber", data);
      if (returns) returns.docNumber = data;
    } catch (error) {}
  };

  useEffect(() => {
    getPurchaseOrderList();
    getReturnTypes();

    if (returns?.returnID) {
      if (returns?.returnID > 0) {
        setSelectedPOid(form.getValues().purchaseOrderID.toString());
        setPostedDate(dayjs(returns?.postingDate, "DD/MM/YYYY").toDate());
        form.setFieldValue("returnNumber", returns.docNumber);
      }
    }

    if (returns?.returnID == 0 && !isUpdate) {
      getNextReturnNumber();
    }
  }, [returns]);

  useEffect(() => {
    if (parseInt(selectedPOid) > 0) getPOById(parseInt(selectedPOid));
  }, [selectedPOid]);

  useEffect(() => {
    if (postedDate) {
      form.setFieldValue("docDate", postedDate ? postedDate.toISOString() : "");
      form.setFieldValue(
        "postingDate",
        postedDate ? postedDate.toISOString() : ""
      );
    } else {
      if (form.getValues().docDate)
        setPostedDate(dateParser(form.getValues().docDate));
    }

    let newReturn = returns;
    if (newReturn) {
      newReturn.createdDate = new Date().toISOString();
      newReturn.postingDate = new Date().toISOString();
      newReturn.docDate = new Date().toISOString();
    }
  }, [postedDate]);

  return (
    <>
      <Box className="fade-in-block width33">
        <NativeSelect
          withAsterisk
          label="Type"
          data={returnTypes}
          {...form.getInputProps("returnTypeID")}
          onChange={(event) => {
            let val = event.currentTarget.value;
            form.setFieldValue("returnTypeID", val != null ? parseInt(val) : 0);

            if (returns) {
              returns.returnTypeID = val != null ? parseInt(val) : 0;
              if (setReturn) setReturn(returns);
            }
          }}
          disabled={isLastStep ? true : false}
        />
        <TextInput
          withAsterisk
          label="Return Number"
          placeholder="enter return number"
          disabled
          {...form.getInputProps("returnNumber")}
        />
        <TextInput
          withAsterisk
          label="Currency"
          placeholder="enter currency name"
          disabled
          {...form.getInputProps("currencyName")}
        />
      </Box>
      <Box className="fade-in-block width33">
        <TextInput
          withAsterisk
          label="Creditor"
          disabled
          placeholder="enter creditor name"
          {...form.getInputProps("creditorName")}
        />
        <DateInput
          value={postedDate}
          onChange={setPostedDate}
          label="Date"
          placeholder="return date"
          dateParser={dateParser}
          valueFormat="MM/DD/YYYY"
          disabled={isLastStep ? true : false}
          withAsterisk
        />
        <Group>
          <NativeSelect
            style={{ width: "85%" }}
            label="BatchID"
            disabled={isLastStep ? true : false}
          />
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            disabled={isLastStep ? true : false}
          >
            <IconSearch />
          </ActionIcon>
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            disabled={isLastStep ? true : false}
          >
            <IconPlus />
          </ActionIcon>
        </Group>
      </Box>
      <Box className="fade-in-block width33">
        <Group>
          <NativeSelect
            withAsterisk
            label="PO Number"
            style={{ width: "93%" }}
            data={poSelections}
            value={selectedPOid}
            {...form.getInputProps("purchaseOrderID")}
            onChange={async (event) => {
              let val = event.currentTarget.value;
              val !== null ? setSelectedPOid(val) : setSelectedPOid("");

              form.setFieldValue(
                "purchaseOrderDocNumber",
                val != null
                  ? poSelections.filter((x: any) => x.value == parseInt(val))[0]
                      .label
                  : ""
              );

              form.setFieldValue(
                "purchaseOrderID",
                val != null
                  ? parseInt(
                      poSelections.filter(
                        (x: any) => x.value == parseInt(val)
                      )[0].value
                    )
                  : 0
              );

              if (returns) {
                returns.purchaseOrderID = parseInt(val !== null ? val : "0");
                if (returns.returnLines.length > 0) {
                  if (!isUpdate) {
                    returns.returnLines = [];
                  }
                }
              }
              getPOById(parseInt(val ? val : "'"));
              getReceiptLines(parseInt(val ? val : "'"));
            }}
            disabled={isLastStep || isUpdate ? true : false}
          />
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={async (e: React.MouseEvent) => {
              e.stopPropagation();
              if (selectedPOid == "0") {
                if (returns) await showPODetails(lineItems);
              } else {
                if (returns) await getReceiptLines(parseInt(selectedPOid));
                if (!isUpdate) showPODetails(lineItems);
              }
            }}
            disabled={isLastStep ? true : false}
          >
            <IconFile />
          </ActionIcon>
        </Group>
      </Box>
    </>
  );
}
