import { Modal, Center } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Utility from "../common/utils";
import { useAuth } from "./user/useAuth";
import { jwtDecode } from "jwt-decode";
import LoggedUser from "../types/login/loggedUser";

type LoginProps = {
  needLogin?: boolean;
};

interface JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
}

interface GoogleJwtPayload extends JwtPayload {
  name: string;
  family_name: string;
  given_name: string;
  hd: string;
  email: string;
  picture?: string;
}

const Login = ({ needLogin = true }: LoginProps) => {
  const [credentials, setCredentials] = useState("");
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(true);
  const { user, login } = useAuth();
  const [isValidUserData, setIsValidUserData] = useState(false);

  const handleLoginSuccess = (credential: string) => {
    // Store JWT in localStorage
    Utility.addToStorage("userCredential", credential);

    // Decode the JWT for user info
    const decodedToken = jwtDecode<GoogleJwtPayload>(credential);

    // Map the decoded token to LoggedUser type
    const loggedUser: LoggedUser = {
      name: decodedToken.name,
      family_name: decodedToken.family_name,
      given_name: decodedToken.given_name,
      hd: decodedToken.hd,
      email: decodedToken.email,
      picture: decodedToken.picture,
    };

    // Log in the user with the decoded data
    login(loggedUser);

    // Navigate after login
    navigate("/");
  };

  return (
    <Modal
      opened={needLogin}
      onClose={close}
      withCloseButton={false}
      centered
      size="sm"
    >
      <Center>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const { credential } = credentialResponse;
            if (credential) {
              handleLoginSuccess(credential);
            } else {
              console.error("No credential received");
            }
          }}
          onError={() => {
            console.error("Google Login failed");
          }}
          type="standard"
        />
      </Center>
    </Modal>
  );
};

export default Login;
