import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Group,
  Textarea,
  Menu,
} from "@mantine/core";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import { CustomFormProvider, useCustomForm } from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";

export default function ItemAdjustmentFormAdd() {
  const navigate = useNavigate();
  const form = useCustomForm({
    initialValues: {
      detail: "",
      itemAdjustmentTypeID: 1,
      siteID: 0
    },
  });
  const onSubmitHandler = async (values: any) => {
    try {
      var data = await commonApi.postApiCall("/inventory/adjustment", values);
      alerts.showMessage("stock adjustment added");
      navigate("/Inventory/Adjustment/Edit/" + data.itemAdjustmentID);
    } catch (error) {
      alerts.showError(error);
    }
  };
  return (
    <>
        <Box className="fade-in-block width33">
            <h3>
                Stock Adjustment Header {" "}
                <Menu trigger="hover" openDelay={100} closeDelay={400} shadow="md" disabled={true}>
                    <Menu.Target>
                        <Button>options</Button>
                    </Menu.Target>
                </Menu>
            </h3>
            <CustomFormProvider form={form}>
                <form
                    onSubmit={form.onSubmit((values) => {
                        onSubmitHandler(values);
                })}>
                <DropdownInput
                    apiURL={"/inventory/adjustment/types"}
                    name={"itemAdjustmentTypeID"}
                    label={"Type"}
                />                        
                <DropdownInput
                    apiURL={"/inventory/site/all"}
                    name={"siteID"}
                    label={"Site"}
                />                                      
                <Textarea
                    withAsterisk
                    label="Description"
                    placeholder="Detail of new stock adjustment"
                    rows={5}
                    {...form.getInputProps("detail")}
                />                
                <Group justify="flex-end" mt="md">
                    <Button
                    onClick={(event) => {
                        navigate("/Inventory/Adjustment/List");
                    }}
                    >
                    Back
                    </Button>
                    <Button type="submit">Add</Button>
                </Group>
                </form>
            </CustomFormProvider>
        </Box>
    </>
  );
}
