import { Box, Stepper, Group, Button } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReceiveAddForm from "./receiveAddForm";
import { useForm } from "@mantine/form";
import Receipt from "../../../types/inventory/receipt";
import ReceiveItemsList from "./receiveItemsList";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";

export default function ReceiveAdd() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [receipt, setReceipt] = useState<Receipt>({
    receiptID: 0,
    docNumber: "",
    creditorReferenceNumber: "",
    docDate: new Date(),
    postingDate: new Date(),
    createdDate: new Date(),
    modifiedDate: new Date(),
    receiptTypeID: 1,
    receiptStatusID: 1,
    purchaseOrderID: 0,
    batchID: 0,
    subLedgerTypeID: 0,
    companyID: 0,
    createdByID: 0,
    codifiedByID: 0,
    receiptTypeName: "",
    receiptStatusName: "",
    purchaseOrderDocNumber: "",
    systemSubLedgerTypeName: "",
    receiptLines: [],
  });

  const form = useForm({
    initialValues: {
      receiptTypeID: 0,
      receiptNumber: "",
      currencyName: "",
      currencyID: 0,
      creditorID: 0,
      creditorName: "",
      creditorDocNumber: "",
      batchID: 0,
      docDate: "",
      postingDate: "",
      createdDate: "",
      modifiedDate: "",
      purchaseOrderDocNumber: "",
      purchaseOrderID: 0,
    },
    validate: {
      receiptTypeID: (value) =>
        value === 0 || value === null ? "Please select type value" : null,
      purchaseOrderID: (value) =>
        value === 0 ? "Please select purchase order" : null,
      createdDate: (value) =>
        value === "" ? "Please select receive date" : null,
    },
  });

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        let validationResult = form.validate();
        if (validationResult.hasErrors) {
          form.setErrors(validationResult.errors);
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    }

    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const addReceiptHandler = () => {
    console.log(receipt);
    try {
      commonApi
        .postApiCall("/purchasing/receipts", receipt)
        .then((response) => {
          navigate("/Inventory/Receive/List");
          alerts.showMessage("Receipt created");
        });

      setCurrentStep(currentStep + 1);
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Receive">
            <ReceiveAddForm
              form={form}
              receipt={receipt}
              setReceipt={setReceipt}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              isUpdate={false}
            />
          </Stepper.Step>
          <Stepper.Step label="Enter Items">
            <ReceiveItemsList
              receipt={receipt}
              setReceipt={setReceipt}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
            />
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <ReceiveAddForm
              form={form}
              receipt={receipt}
              setReceipt={setReceipt}
              isLastStep={true}
            />
            <ReceiveItemsList
              receipt={receipt}
              setReceipt={setReceipt}
              isLastStep={true}
            />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 3}>
              Next
            </Button>
          ) : (
            <Button onClick={addReceiptHandler}>Save</Button>
          )}

          <Button onClick={() => navigate("/Inventory/Receive/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
