import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Receipt from "../../../types/inventory/receipt";
import { useForm } from "@mantine/form";
import commonApi from "../../../api/commonApi";
import ReceiptLine from "../../../types/inventory/receiptLine";
import { Box, Button, Group, Stepper } from "@mantine/core";
import ReceiveAddForm from "./receiveAddForm";
import ReceiveItemsList from "./receiveItemsList";
import alerts from "../../common/alerts";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";

export default function ReceiveEdit() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { receiptID } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [lineItems, setLineItems] = useState<ReceiptLine[]>([]);
  const [receipt, setReceipt] = useState<Receipt>({
    receiptID: 0,
    docNumber: "",
    creditorReferenceNumber: "",
    docDate: new Date(),
    postingDate: new Date(),
    createdDate: new Date(),
    modifiedDate: new Date(),
    receiptTypeID: 1,
    receiptStatusID: 1,
    purchaseOrderID: 0,
    batchID: 0,
    subLedgerTypeID: 0,
    companyID: 0,
    createdByID: 0,
    codifiedByID: 0,
    receiptTypeName: "",
    receiptStatusName: "",
    purchaseOrderDocNumber: "",
    systemSubLedgerTypeName: "",
    receiptLines: [],
  });

  const form = useForm({
    initialValues: {
      receiptTypeID: 0,
      receiptNumber: "",
      currencyName: "",
      currencyID: 0,
      creditorID: 0,
      creditorName: "",
      creditorDocNumber: "",
      batchID: 0,
      docDate: "",
      postingDate: "",
      createdDate: "",
      modifiedDate: "",
      purchaseOrderDocNumber: "",
      purchaseOrderID: 0,
    },
    validate: {},
  });

  const getReceiptById = async () => {
    try {
      let data = await commonApi.getApiCall("purchasing/receipts/" + receiptID);
      form.setValues(data);
      form.setFieldValue("currencyName", data.purchaseOrder.currencyName);
      form.setFieldValue("creditorName", data.purchaseOrder.creditorName);
      form.setFieldValue("purchaseOrderID", data.purchaseOrder.purchaseOrderID);
      setReceipt(data);
      setLineItems(data.receiptLines);
    } catch (error) {}
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        console.log(receipt);

        let validationResult = form.validate();
        if (validationResult.hasErrors) {
          form.setErrors(validationResult.errors);
        } else {
          try {
            commonApi
              .putApiCall("/purchasing/receipts/" + receiptID, receipt)
              .then((response) => {
                alerts.showMessage("Receipt updated");
              });

            setCurrentStep(currentStep + 1);
          } catch (error) {
            alerts.showError(error);
          }
        }
      }

      if (currentStep === 1) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const updateReceiptHandler = () => {
    try {
      commonApi
        .putApiCall("/purchasing/receipts/" + receiptID, receipt)
        .then((response) => {
          navigate("/Inventory/Receive/List");
          alerts.showMessage("Receipt updated");
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getReceiptById();
  }, []);

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Receive">
            <ReceiveAddForm
              form={form}
              receipt={receipt}
              setReceipt={setReceipt}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
              isUpdate={true}
            />
          </Stepper.Step>
          <Stepper.Step label="Enter Items">
            <ReceiveItemsList
              receipt={receipt}
              setReceipt={setReceipt}
              purchaseOrder={purchaseOrder}
              setPurchaseOrder={setPurchaseOrder}
            />
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <ReceiveAddForm
              form={form}
              receipt={receipt}
              setReceipt={setReceipt}
              isLastStep={true}
            />
            <ReceiveItemsList
              receipt={receipt}
              setReceipt={setReceipt}
              isLastStep={true}
            />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 3}>
              Next
            </Button>
          ) : (
            <Button onClick={updateReceiptHandler}>Update</Button>
          )}

          <Button onClick={() => navigate("/Inventory/Receive/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
