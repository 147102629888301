import { DataTable, DataTableSortStatus } from "mantine-datatable";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import Receipt from "../../../types/inventory/receipt";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ActionIcon, Box, Group } from "@mantine/core";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import commonApi from "../../../api/commonApi";
import ReceiptLine from "../../../types/inventory/receiptLine";
import { openModal } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import ReceiveItemsUpdateQuantity from "./receiveItemsUpdateQuantity";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import PurchaseOrderLineItem from "../../../types/purchasing/purchaseOrderLineItem";

export default function ReceiveItemsList({
  receipt,
  setReceipt,
  purchaseOrder,
  setPurchaseOrder,
  isLastStep,
}: {
  receipt?: Receipt;
  setReceipt?: Dispatch<SetStateAction<Receipt>>;
  purchaseOrder?: PurchaseOrder;
  setPurchaseOrder?: Dispatch<SetStateAction<PurchaseOrder>>;
  isLastStep?: boolean;
}) {
  const [selectedRecords, setSelectedRecords] = useState<ReceiptLine[]>([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<ReceiptLine>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [receiptLineItem, setReceiptLineItem] = useState<ReceiptLine>({
    receiptLineID: 0,
    lineNumber: 0,
    quantityOrdered: 0,
    quantityInvoiced: 0,
    quantityReceived: 0,
    unitPrice: 0,
    lineTotal: 0,
    receiptID: 0,
    receiptDocNumber: "",
    itemID: 0,
    itemName: "",
    itemDescription: "",
    siteID: 0,
    siteName: "",
    unitOfMeasureID: 0,
    unitOfMeasureName: "",
    purchaseOrderLineID: 0,
    purchaseOrderDocNumber: "",
    trackingOptionID: 1,
  });

  const showReceiveItemModal = (record: ReceiptLine) => {
    openModal({
      modalId: "edit",
      title: "Update Quantity",
      size: "lg",
      children: (
        <BrowserRouter>
          <ReceiveItemsUpdateQuantity
            receiptLineItem={record}
            setReceiptLineItem={setReceiptLineItem}
            setState={setIsSuccessUpdate}
          ></ReceiveItemsUpdateQuantity>
        </BrowserRouter>
      ),
    });
  };

  useEffect(() => {
    receipt?.receiptLines.forEach((lineItem: ReceiptLine) => {
      if (lineItem.itemID > 0) {
        if (purchaseOrder?.purchaseOrderLines) {
          let poLineItem: PurchaseOrderLineItem =
            purchaseOrder.purchaseOrderLines.filter(
              (x) => x.itemID === lineItem.itemID
            )[0];

          if (poLineItem) {
            lineItem.itemDescription = poLineItem.itemDescription
              ? poLineItem.itemDescription
              : "";

            lineItem.purchaseOrderDocNumber = poLineItem.purchaseOrderDocNumber
              ? poLineItem.purchaseOrderDocNumber
              : "";

            lineItem.quantityOrdered = poLineItem.quantity
              ? poLineItem.quantity
              : 0;
          }
        }
      }
    });

    if (receipt?.receiptLines.length === 0) {
      if (purchaseOrder?.purchaseOrderLines) {
        let receiptLines: ReceiptLine[] = [];
        if (purchaseOrder?.purchaseOrderLines.length > 0) {
          purchaseOrder?.purchaseOrderLines.forEach(
            (item: PurchaseOrderLineItem) => {
              let receiptLine: ReceiptLine = {
                receiptLineID: 0,
                purchaseOrderDocNumber: item.purchaseOrderDocNumber
                  ? item.purchaseOrderDocNumber
                  : "",
                lineNumber: item.lineNumber ? item.lineNumber : 0,
                quantityOrdered: item.quantity ? item.quantity : 0,
                quantityInvoiced: item.quantity ? item.quantity : 0,
                quantityReceived: item.quantity ? item.quantity : 0,
                unitPrice: item.unitPrice ? item.unitPrice : 0,
                lineTotal: item.lineTotal ? item.lineTotal : 0,
                receiptID: 0,
                receiptDocNumber: "",
                itemID: item.itemID ? item.itemID : 0,
                itemName: item.itemName ? item.itemName : "",
                itemDescription: item.itemDescription
                  ? item.itemDescription
                  : "",
                siteID: item.siteID ? item.siteID : 0,
                siteName: item.siteName ? item.siteName : "",
                unitOfMeasureID: item.unitOfMeasureID
                  ? item.unitOfMeasureID
                  : 0,
                unitOfMeasureName: item.unitOfMeasureName
                  ? item.unitOfMeasureName
                  : "",
                purchaseOrderLineID: item.purchaseOrderLineID
                  ? item.purchaseOrderLineID
                  : 0,
                trackingOptionID: 1,
              };
              receiptLines.push(receiptLine);
            }
          );
        }
        receipt.receiptLines = receiptLines;
      }
    }
  }, []);

  return (
    <>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        height="50%"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        minHeight={150}
        records={receipt?.receiptLines}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        idAccessor="purchaseOrderLineID"
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        columns={[
          {
            accessor: "purchaseOrderDocNumber",
            title: "PO Number",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "itemName",
            title: "Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "itemDescription",
            title: "Description",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitOfMeasureName",
            title: "Unif of Measure",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "siteName",
            title: "Site Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "quantityReceived",
            title: "Quantity Received",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "quantityOrdered",
            title: "Quantity Ordered",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitPrice",
            title: "Unit Cost",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (receiptline) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    let newReceiptLines = receipt?.receiptLines.filter(
                      (x) => x.itemID != receiptline.itemID
                    );
                    if (receipt?.receiptLines)
                      receipt.receiptLines = newReceiptLines
                        ? newReceiptLines
                        : [];
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          if (!isLastStep) {
            setReceiptLineItem(record);
            if (receiptLineItem) showReceiveItemModal(record);
          }
        }}
        isRecordSelectable={() => !isLastStep}
      />
    </>
  );
}
