import { TextInput, Box, NativeSelect, Group, Button } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ReceiveCreateBatch() {
  const [originList, setOriginiList] = useState<any[]>([]);
  const [postingDate, setPostingDate] = useState<Date | null>(null);
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      batchID: "",
      originID: 0,
      comment: "",
      postingDate: "",
    },
    validate: {
      originID: (value) => (value === 0 ? "Please select origin" : null),
      batchID: (value) =>
        value.length < 2 ? "Batch ID must have at least 2 characters" : null,
      comment: (value) =>
        value.length < 2 ? "Comment must have at least 2 characters" : null,
    },
  });
  const onSubmitHandler = (values: any) => {};
  return (
    <Box className="fade-in-block width100">
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <TextInput
          withAsterisk
          label="Batch ID"
          placeholder="batch ID"
          {...form.getInputProps("batchID")}
        />
        <NativeSelect
          label="Origin"
          data={originList}
          withAsterisk
          {...form.getInputProps("originID")}
        />
        <TextInput
          withAsterisk
          label="Comment"
          placeholder="comment"
          {...form.getInputProps("comment")}
        />
        <DateInput
          value={postingDate}
          onChange={setPostingDate}
          label="Date"
          placeholder="receive date"
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/Tax/RateDetail/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Box>
  );
}
