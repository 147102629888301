import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../api/commonApi";
import Journal from "../../types/journal/journal";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import JournalSearch from "./journalSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import JournalFormEdit from "./journalFormEdit";
import extensionMethods from "../../common/extensionMethods";
import alerts from "../common/alerts";

export default function JournalList() {
  const navigate = useNavigate();
  const [journals, setJournals] = useState<Journal[]>([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Journal>>({
    columnAccessor: "",
    direction: "asc",
  });
  const getJournalList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/journals?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setJournals(data);
    }
  };

  useEffect(() => {
    getJournalList();
  }, [sortStatus, page, pageSize, params]);

  function showEditModal(
    journalID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    openModal({
      modalId: "edit",
      title: "Showing journal information",
      size: "100vw",
      children: (
        <BrowserRouter>
          <JournalFormEdit id={journalID.toString()} setState={setState} />
        </BrowserRouter>
      ),
    });
  }

  function showDeleteModal(journalID: number) {
    modals.openConfirmModal({
      title: "Delete journal",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this journal/s.</Text>
      ),
      labels: { confirm: "Delete journal", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi.deleteApiCall("/journals/" + journalID).then(() => {
            alerts.showMessage("Journal deleted");
            getJournalList();
          });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }

  return (
    <div className="fade-in-block width100">
      <JournalSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={journals}
        idAccessor="JournalID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "journalID",
            title: "ID",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,            
            render: (journal) => journal.journalID,
            width: "1%",
          },          
          {
            accessor: "detail",
            title: "Detail",
            textAlign: "left",
            sortable: true,
            render: (journal) => journal.detail,
          },
          {
            accessor: "journalDate",
            title: "Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ journalDate }) =>
              extensionMethods.dateDisplay(journalDate),
            width: "8%",
          },
          {
            accessor: "journalTypeName",
            title: "Type",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "transactionTypeName",
            title: "Transaction Type",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            width: "8%",
          },
          {
            accessor: "creditSourceTotal",
            title: "Source Credit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (journal) =>
              extensionMethods.decimalDisplay(journal.creditSourceTotal, true) +
              (journal.creditSourceTotal === 0
                ? ""
                : " " + journal.sourceCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "targetSourceTotal",
            title: "Target Credit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (journal) =>
              extensionMethods.decimalDisplay(journal.creditTargetTotal, true) +
              (journal.creditTargetTotal === 0
                ? ""
                : " " + journal.targetCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "debitSourceTotal",
            title: "Source Debit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (journal) =>
              extensionMethods.decimalDisplay(journal.debitSourceTotal, true) +
              (journal.debitSourceTotal === 0
                ? ""
                : " " + journal.sourceCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "debitTargetTotal",
            title: "Target Debit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (journal) =>
              extensionMethods.decimalDisplay(journal.debitTargetTotal, true) +
              (journal.debitTargetTotal === 0
                ? ""
                : " " + journal.targetCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "left",
            sortable: true,
            width: "10%",
          },
          {
            accessor: "postingDate",
            title: "Posted Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ postingDate }) =>
              extensionMethods.dateDisplay(postingDate),
            width: "10%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (journal) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal(journal.journalID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  disabled={journal.canDelete ? false : true}
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(journal.journalID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Journal/Edit/" + record.journalID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
