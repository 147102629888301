import { Box, Button, Group, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction } from "react";
import ReceiptLine from "../../../types/inventory/receiptLine";
import { closeAllModals } from "@mantine/modals";

export default function ReceiveItemsUpdateQuantity({
  receiptLineItem,
  setReceiptLineItem,
  setState,
}: {
  receiptLineItem: ReceiptLine;
  setReceiptLineItem: Dispatch<SetStateAction<ReceiptLine>>;
  setState: Dispatch<SetStateAction<boolean>>;
}) {
  let maxNumber = receiptLineItem.quantityOrdered;
  const lineItemForm = useForm({
    initialValues: {
      receiptLineID: receiptLineItem.receiptLineID,
      lineNumber: receiptLineItem.lineNumber,
      quantityOrdered: receiptLineItem.quantityOrdered,
      quantityInvoiced: receiptLineItem.quantityInvoiced,
      quantityReceived: receiptLineItem.quantityReceived,
      unitPrice: receiptLineItem.unitPrice,
      lineTotal: receiptLineItem.lineTotal,
      receiptID: receiptLineItem.receiptID,
      receiptDocNumber: receiptLineItem.receiptDocNumber,
      itemID: receiptLineItem.itemID,
      itemName: receiptLineItem.itemName,
      itemDescription: receiptLineItem.itemDescription,
      siteID: receiptLineItem.siteID,
      siteName: receiptLineItem.siteName,
      unitOfMeasureID: receiptLineItem.unitOfMeasureID,
      unitOfMeasureName: receiptLineItem.unitOfMeasureName,
      purchaseOrderLineID: receiptLineItem.purchaseOrderLineID,
      purchaseOrderDocNumber: receiptLineItem.purchaseOrderDocNumber,
      trackingOptionID: receiptLineItem.trackingOptionID,
    },
  });

  const updateHandler = () => {
    receiptLineItem.quantityInvoiced =
      lineItemForm.getValues().quantityInvoiced;
    receiptLineItem.quantityOrdered = lineItemForm.getValues().quantityOrdered;
    receiptLineItem.quantityReceived =
      lineItemForm.getValues().quantityReceived;

    setReceiptLineItem(receiptLineItem);
    closeAllModals();
  };

  return (
    <>
      <Box className="fade-in-block width66">
        <TextInput
          label="Item Name"
          disabled
          {...lineItemForm.getInputProps("itemName")}
        />
        <TextInput
          label="Item Description"
          disabled
          {...lineItemForm.getInputProps("itemDescription")}
        />
        <NumberInput
          label="Quantity Ordered"
          placeholder="Enter quantity"
          allowNegative={false}
          disabled
          {...lineItemForm.getInputProps("quantityOrdered")}
        />
        <NumberInput
          label="Quantity Received"
          placeholder="Enter quantity"
          allowNegative={false}
          max={maxNumber}
          {...lineItemForm.getInputProps("quantityReceived")}
        />
      </Box>
      <Group justify="flex-end" mt="md">
        <Button
          onClick={(event) => {
            closeAllModals();
          }}
        >
          Cancel
        </Button>
        <Button onClick={updateHandler}>Update</Button>
      </Group>
    </>
  );
}
