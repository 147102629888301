import { useDisclosure } from "@mantine/hooks";
import { AppShell } from "@mantine/core";
import "./App.css";
import Header from "./navigation/header";
import RouteSwitcher from "./navigation/routerSwitcher";
import { useEffect } from "react";
import Utility from "./common/utils";
import Navbar2 from "./navigation/navbar2";
import PageHeader from "./navigation/pageHeader";
import { closeAllModals } from "@mantine/modals";
import { useAuth } from "./components/user/useAuth";
import Login from "./components/LoginModal";

function App() {
  const [opened, { toggle }] = useDisclosure();
  const token = Utility.getValueFromStorage("userCredential");
  const { logout } = useAuth();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Utility.isTokenExpired()) {
        console.log("Token Expired");
        closeAllModals();
        logout();
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [token]);

  return (
    <div className="App" style={{ marginTop: "20px" }}>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <Header toggle={toggle} opened={opened} />
        {token ? <Navbar2 toggleMobileMenu={toggle} /> : <></>}
        <AppShell.Main>
          {token ? <PageHeader /> : <></>}
          <RouteSwitcher />
        </AppShell.Main>
        <AppShell.Footer zIndex={opened ? "auto" : 201}>
          <div className="App center">Development Version for Staging</div>
        </AppShell.Footer>
      </AppShell>
      <Login needLogin={!token} />
    </div>
  );
}

export default App;
