import { Box, Group, ActionIcon, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { DataTableSortStatus, DataTable } from "mantine-datatable";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import ReceiveSearch from "../receive/receiveSearch";
import Return from "../../../types/inventory/return";
import ReturnsSearch from "./returnsSearch";

export default function ReturnsList() {
  const navigate = useNavigate();
  const [returnList, setReturnList] = useState<Return[]>([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Return>>({
    columnAccessor: "",
    direction: "asc",
  });

  const getReturnsList = async () => {
    let data: Return[] = [];
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/purchasing/returns?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setReturnList(data);
    }
  };

  const showDeleteModal = (returnID: number) => {
    modals.openConfirmModal({
      title: "Delete return",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this return.</Text>
      ),
      labels: { confirm: "Delete return", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("purchasing/returns/" + returnID)
            .then(() => {
              alerts.showMessage("Return deleted");
              getReturnsList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  };

  useEffect(() => {
    getReturnsList();
  }, [sortStatus, page, pageSize, params]);

  return (
    <div className="fade-in-block width100">
      <ReturnsSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={returnList}
        idAccessor="returnID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "docNumber",
            title: "Return Number",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "purchaseOrderDocNumber",
            title: "PO Number",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingDate",
            title: "Posting Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ postingDate }) =>
              dayjs(postingDate).format("MMM DD YYYY"),
          },
          {
            accessor: "returnTypeName",
            title: "Return Type",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },

          {
            accessor: "returnStatusName",
            title: "Return Status",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (returns) => (
              <Group gap={4} justify="right" wrap="nowrap">
                {/* <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showEditModal(bank.bankID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon> */}
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (returns.returnID) showDeleteModal(returns.returnID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Inventory/Returns/Edit/" + record.returnID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
