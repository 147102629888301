import { TextInput } from "@mantine/core";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";
import { CustomFormProvider, useCustomForm } from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";

function AccountSearch(props: any) {
  const form = useCustomForm({
    initialValues: {
      accountNumber: "",
      name: "",
      accountCategoryID: "0",
      accountCategoryTypeID: "0",
    },
  });
  return (
    <CustomFormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
        <SearchSection
          buttonSection={
            <ButtonGroup
              cancelLabel="Reset"
              cancelClick={form.reset}
              okLabel="Search"
            />
          }
        >
          <TextInput
            label="Account Number"
            placeholder="search by account number"
            {...form.getInputProps("accountNumber")}
          />
          <TextInput
            label="Name"
            placeholder="search by name"
            {...form.getInputProps("name")}
          />
          <DropdownInput
              apiURL={"/account/categories"}
              name={"accountCategoryID"}
              label={"Account Category"}
          />
          <DropdownInput
              apiURL={"/account/category/types"}
              name={"accountCategoryTypeID"}
              label={"Account Category Type"}
          />
        </SearchSection>
      </form>
    </CustomFormProvider>
  );
}
export default AccountSearch;
