import { TextInput, Checkbox } from "@mantine/core";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";
import {
  CustomFormProvider,
  useCustomForm,
} from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";

export default function ItemSearch(props: any) {
  const form = useCustomForm({
    initialValues: {
      search: "",
      itemTypeID: 0,
      categoryID: 0,
      disabled: false,
      valuationMethodID: 0,
      unitOfMeasureID: 0,
      trackingOptionId: 0,
      salesTaxOptionID: 0,
      purchaseTaxOptionID: 0,
    },
  });
  return (
    <CustomFormProvider form={form}>
      <form
        onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}
      >
        <SearchSection
          buttonSection={
            <ButtonGroup
              cancelLabel="Reset"
              cancelClick={form.reset}
              okLabel="Search"
            />
          }
        >
          <TextInput
            label="Search"
            placeholder="general text search"
            {...form.getInputProps("search")}
          />
          <DropdownInput
            apiURL={"/inventory/itemtypes"}
            name={"itemTypeID"}
            label={"Item Type"}
          />
          <DropdownInput
            apiURL={"/inventory/categories"}
            name={"categoryID"}
            label={"Category"}
          />
          <DropdownInput
            apiURL={"/inventory/valuationmethods"}
            name={"valuationMethodID"}
            label={"Valuation Method"}
          />
          <DropdownInput
            apiURL={"/inventory/unitofmeasures"}
            name={"unitOfMeasureID"}
            label={"Unit of Measure"}
          />
          <DropdownInput
            apiURL={"/inventory/trackingoptions"}
            name={"trackingOptionID"}
            label={"Tracking Option"}
          />
          <DropdownInput
            apiURL={"/inventory/taxoptions"}
            name={"salesTaxOptionID"}
            label={"Sales Tax Option"}
            description="The tax option to use for sales transactions"
          />
          <DropdownInput
            apiURL={"/inventory/taxoptions"}
            name={"purchaseTaxOptionID"}
            label={"Purchase Tax Option"}
            description="The tax option to use for purchase transactions"
          />
          <Checkbox label="Disabled" {...form.getInputProps("disabled")} />
        </SearchSection>
      </form>
    </CustomFormProvider>
  );
}
