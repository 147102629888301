import { Box, SimpleGrid, Stepper, Group, Button } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useForm } from "@mantine/form";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import alerts from "../common/alerts";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";
import PurchaseBasicForm from "./purchaseBasicForm";
import PurchaseOrderLineForm from "./purchaseOrderLineForm";
import PurchaseOrderLineTable from "./purchaseOrderLineTable";
import PurchaseOrderSummary from "./purchaseOrderSummary";

export default function PurchaseOrderAddForm() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [lineItems, setLineItems] = useState<PurchaseOrderLineItem[]>([]);
  const [poToCreate, setPoToCreate] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [lineItemToEdit, setLineItemToEdit] = useState<PurchaseOrderLineItem>(
    {}
  );
  const [lineItemNo, setLineItemNo] = useState(0);

  const basicInfoForm = useForm({
    initialValues: {
      createdByID: 0,
      creditorID: 0,
      taxScheduleID: 0,
      docNumber: "",
      currencyID: 0,
    },
    validate: {
      docNumber: (value) =>
        value.length < 2 ? "PO number must have at least 2 characters" : null,
      createdByID: (value: number) =>
        value === 0 ? "Please select buyer" : null,
      taxScheduleID: (value: number) =>
        value === 0 ? "Please select tax schedule" : null,
      currencyID: (value: number) =>
        value === 0 ? "Please select currency" : null,
      creditorID: (value: number) =>
        value === 0 ? "Please select creditor" : null,
    },
  });

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        let creditorID = basicInfoForm.getInputProps("creditorID").value;
        let taxSchedID = basicInfoForm.getInputProps("taxScheduleID").value;
        let currencyID = basicInfoForm.getInputProps("currencyID").value;
        let createdByID = basicInfoForm.getInputProps("createdByID").value;
        let docNumber = basicInfoForm.getInputProps("docNumber").value;

        let PO: PurchaseOrder = {
          docNumber: docNumber,
          createdByID: parseInt(createdByID),
          creditorID: parseInt(creditorID),
          taxScheduleID: parseInt(taxSchedID),
          currencyID: parseInt(currencyID),
          currencyName: poToCreate.currencyName,
          taxScheduleName: poToCreate.taxScheduleName,
          createdByName: poToCreate.createdByName,
          creditorName: poToCreate.creditorName,
          statusID: 1,
          subLedgerTypeID: 1,
          total: 0,
          subTotal: 0,
          taxAmount: 0,
          freight: 0,
          misc: 0,
          tradeDiscount: 0,
        };

        let validationResult = basicInfoForm.validate();
        if (validationResult.hasErrors) {
          basicInfoForm.setErrors(validationResult.errors);
        } else {
          try {
            // commonApi
            //   .postApiCall("/purchasing/purchaseorders", PO)
            //   .then((response) => {
            //     alerts.showMessage("PO added");
            //   });
            setCurrentStep(currentStep + 1);
            setPoToCreate(PO);
          } catch (error) {
            alerts.showError(error);
          }
        }
      }

      if (currentStep === 1) {
        console.log(poToCreate);
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const addPoHandler = async () => {
    try {
      commonApi
        .postApiCall("/purchasing/purchaseorders", poToCreate)
        .then((response) => {
          navigate("/Finance/Purchasing");
          alerts.showMessage("Purchase order added");
        });

      setCurrentStep(currentStep + 1);
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Create PO">
            <PurchaseBasicForm
              basicInfoForm={basicInfoForm}
              purchaseOrder={poToCreate}
              setPurchaseOrder={setPoToCreate}
            />
          </Stepper.Step>
          <Stepper.Step label="Select Items">
            <Box>
              <SimpleGrid cols={2}>
                <PurchaseOrderLineForm
                  purchaseOrder={poToCreate}
                  setPurchaseOrder={setPoToCreate}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  lineItemToEdit={lineItemToEdit}
                  setLineItemToEdit={setLineItemToEdit}
                  lineItemNo={lineItemNo}
                  setLineItemNo={setLineItemNo}
                />
                <PurchaseOrderLineTable
                  purchaseOrder={poToCreate}
                  setPurchaseOrder={setPoToCreate}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  lineItemToEdit={lineItemToEdit}
                  setLineItemToEdit={setLineItemToEdit}
                  lineItemNo={lineItemNo}
                  setLineItemNo={setLineItemNo}
                />
              </SimpleGrid>
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <Box>
              <PurchaseOrderSummary
                purchaseOrder={poToCreate}
                setPurchaseOrder={setPoToCreate}
                lineItems={lineItems}
                setLineItems={setLineItems}
                lineItemToEdit={lineItemToEdit}
                setLineItemToEdit={setLineItemToEdit}
              />
            </Box>
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button
              onClick={handleNextStep}
              disabled={currentStep === 3}
              type="submit"
            >
              Next
            </Button>
          ) : (
            <Button onClick={addPoHandler}>Save</Button>
          )}

          <Button onClick={() => navigate("/Finance/Purchasing")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
