import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NumberInput, NativeSelect, Button, Group, Text,  Menu, Checkbox, Fieldset, SimpleGrid, Switch } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";
import { closeAllModals, modals, openModal } from "@mantine/modals";
import NavLinkIcon from "../../../navigation/navLinkIcon";
import SubLedgerTypeActionMapSourceFormEdit from "../../generalLedger/subLedgerTypeActionMap/subLedgerTypeActionMapSourceFormEdit";



export default function ItemFormEdit({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  })
  {
    const navigate = useNavigate();
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);  
    const form = useForm();  
    const [value, setValue] = useState('');
    const {itemID} = useParams();
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = itemID;
    } 

    const getItemById = async () => {
      try {
        let data = await commonApi.getApiCall("/inventory/items/" + itemID);
        form.setValues(data);
      } catch (error) {}
    };    
    const [valuationMethods, setValuationMethods] = useState<OptionItem[]>([]);
    const getValuationMethodList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/valuationmethods");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setValuationMethods(data2);               
      }
    };  
    const [itemTypes, setItemTypes] = useState<OptionItem[]>([]);
    const getItemTypeList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/itemtypes");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        setItemTypes(data2);
        //set default value for itemTypeID field on form
        form.setFieldValue("itemTypeID", Number(data2[0].value)); 
      }
    }; 
    const [categories, setCategories] = useState<OptionItem[]>([]);
    const getCategoryList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/categories");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setCategories(data2);               
      }
    };
    const [taxOptions, setTaxOptions] = useState<OptionItem[]>([]);
    const getTaxOptionList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/taxoptions");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setTaxOptions(data2);               
      }
    };
    const [unitOfMeasures, setUnitofMeasures] = useState<OptionItem[]>([]);
    const getUnitOfMeasureList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/unitofmeasures");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setUnitofMeasures(data2);
      }
    };
    const [trackingOptions, setTrackingOptions] = useState<OptionItem[]>([]);
    const getTrackingOptionList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/trackingoptions");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setTrackingOptions(data2);               
      }
    };
    
    useEffect(() => {      
      getItemById();
      getValuationMethodList();
      getItemTypeList();
      getCategoryList();
      getTaxOptionList();
      getUnitOfMeasureList();
      getTrackingOptionList();
    }, 
    [id]);    

    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.putApiCall("/inventory/items/" + id, values).then(() => {
          alerts.showMessage("Item updated");
          if (isPopup) {
            if (setState){
              setState(true);
            }
          } 
          navigate("/Inventory/Item/List");
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    

    function showDeleteModal(
      itemID: number)
    {
      modals.openConfirmModal({
        title: "Delete item",
        centered: true,
        children: (
          <Text size="sm">Are you sure you want to delete this item/s?</Text>
        ),
        labels: { confirm: "Delete item", cancel: "Back" },
        confirmProps: { color: "red" },
        onCancel: () => {},
        onConfirm: async () => {
          try {
            await commonApi.deleteApiCall("/inventory/items/" + itemID).then(() => {
              alerts.showMessage("Item deleted");
              navigate("/Inventory/Item/List");
            });
          } 
          catch (error) {
            alerts.showError(error);
          }
        },
      });
    }
  
    function showAccountMappingModal() {
      openModal({
        modalId: "view",
        title: "Account Mapping",
        size: "100vw",
        children: (
          <BrowserRouter>
            <SubLedgerTypeActionMapSourceFormEdit subLedgerTypeSourceID={form.values.itemID} subLedgerTypeID={form.values.subLedgerTypeID} />
          </BrowserRouter>
        ),
      });
    }

    return (
      <>
        <Box>
          <h3>
            Item Header <Menu trigger="hover" openDelay={100} closeDelay={400} shadow="md">
              <Menu.Target>
                <Button>options</Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Item Functions</Menu.Label>
                <Menu.Item 
                  leftSection={NavLinkIcon("IconDelete", false)}
                  onClick={(e: React.MouseEvent) => 
                    {
                      e.stopPropagation();
                      showDeleteModal(
                        id ? parseInt(id) : 0);
                    }}>Delete</Menu.Item>
              <Menu.Label>Account Mapping</Menu.Label>
              <Menu.Item
                leftSection={NavLinkIcon("IconAction", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showAccountMappingModal();                  
                }}
              >
                View
              </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </h3>
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmitHandler(values);
            })}>
            <SimpleGrid cols={2}>
              <Fieldset legend="Core Details">
                <TextInput 
                    name="name" 
                    label="Name"
                    required
                    {...form.getInputProps("name")}/>

                <TextInput 
                  name="description" 
                  label="Description"
                  {...form.getInputProps("description")}/>

                <NumberInput
                  name="listCost"
                  label="List Cost"
                  description="The RRP cost of the item"
                  prefix="$"
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("listCost")}/>
                
                <NumberInput
                  label="Current Cost"
                  description="The weighted average cost of the item"
                  disabled
                  prefix="$"
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("currentCost")}/>                  

                <Switch
                  name="disabled" 
                  label="Disabled"
                  {...form.getInputProps("disabled", {type: "checkbox"})}/>
                  
                <Switch
                      name="allowBackorder" 
                      label="Allow Backorder"
                      {...form.getInputProps("allowBackorder")}/>
              </Fieldset>

              <Fieldset legend="Extended Properties">
                <NativeSelect 
                  name="itemTypeID" 
                  label="Item Type" 
                  required
                  data={itemTypes}
                  {...form.getInputProps("itemTypeID")}
                  onChange={(event) => setValue(event.currentTarget.value)}/>
                
                <NativeSelect 
                  name="categoryID" 
                  label="Item Category" 
                  data={categories}
                  {...form.getInputProps("categoryID")}/>
                
                <NativeSelect 
                  name="valuationMethodID" 
                  label="Valuation Method" 
                  data={valuationMethods}
                  {...form.getInputProps("valuationMethodID")}/>

                <NativeSelect 
                  name="unitOfMeasureID" 
                  label="Unit of Measure" 
                  data={unitOfMeasures}
                  {...form.getInputProps("unitOfMeasureID")}/>

                <NativeSelect 
                  name="trackingOptionID" 
                  label="Tracking Option" 
                  data={trackingOptions}
                  {...form.getInputProps("trackingOptionID")}/>
                
                <NativeSelect 
                  name="salesTaxOptionID" 
                  label="Sales Tax Option" 
                  description="The tax option to use for sales transactions"
                  data={taxOptions}
                  {...form.getInputProps("salesTaxOptionID")}/>
                
                <NativeSelect 
                  name="purchaseTaxOptionID"
                  label="Purchase Tax Option" 
                  description="The tax option to use for purchase transactions"
                  data={taxOptions}
                  {...form.getInputProps("purchaseTaxOptionID")}/>               
              </Fieldset>              
          </SimpleGrid>              

            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  if (isPopup) {
                    closeAllModals();
                  } else {
                    event.preventDefault();
                    navigate("/Inventory/Item/List");
                  }
                }}>
                Back
              </Button>
              <Button type="submit">Update</Button>
            </Group>
          </form>
        </Box>
      </>
    );
  }