import { Box, TextInput, NativeSelect, Menu, Button } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../api/commonApi";
import Utility from "../../common/utils";
import OptionItem from "../../types/common/optionItem";
import Company from "../../types/company/company";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import { openModal } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import PurchaseOrderStatusUpdate from "./purchaseOrderStatusUpdate";
import PurchaseOrderCopyLineItems from "./purchaseOrderCopyLineItems";

interface formValues {
  createdByID: number;
  creditorID: number;
  taxScheduleID: number;
  docNumber: string;
  currencyID: number;
}

export default function PurchaseBasicForm({
  basicInfoForm,
  purchaseOrder,
  setPurchaseOrder,
}: {
  basicInfoForm: UseFormReturnType<formValues>;
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>;
}) {
  //const currentUser = JSON.parse(Utility.getValueFromStorage("user"));
  const [currencyOptions, setCurrencyOptions] = useState<OptionItem[]>([]);
  const [creditors, setCreditors] = useState<OptionItem[]>([]);
  const [taxSchedules, setTaxSchedules] = useState<OptionItem[]>([]);
  const [poTypes, setPoTypes] = useState<OptionItem[]>([]);
  const [users, setUsers] = useState<OptionItem[]>([]);
  const [company, setCompany] = useState<Company>({} as Company);
  const [currencyID, setCurrencyID] = useState("");
  const [buyerID, setBuyerID] = useState("");
  const [nextPoId, setNextPoId] = useState("");
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);

  const getCurrencyOptions = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCurrencyOptions(data2);
    }
  };

  const getCreditors = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/creditors");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCreditors(data2);
    }
  };

  const getTaxSchedules = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/schedules");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setTaxSchedules(data2);
    }
  };

  const getUsers = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/companies/userviews");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setUsers(data2);

      //let buyer = data2.filter((x: any) => x.label === currentUser.name);
      //setBuyerID(buyer[0].value);
    }
  };

  const getCompanyById = async () => {
    let data = null;
    try {
      let id = 0;
      data = await commonApi.getApiCall("/companies/" + id);
      if (data) setCompany(data);

      setCurrencyID(data.currencyID.toString());
    } catch (error) {}
  };

  const getNextPoNumber = async () => {
    let data = null;

    try {
      data = await commonApi.getApiCall("/purchasing/purchaseorders/nextid");
      setNextPoId(data);
    } catch (error) {}
  };

  const showEditModal = ({
    purchaseOrder,
    setState,
  }: {
    purchaseOrder: PurchaseOrder;
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    openModal({
      modalId: "edit",
      title: "Edit Purchase Order Status",
      size: "lg",
      children: (
        <BrowserRouter>
          <PurchaseOrderStatusUpdate
            purchaseOrder={purchaseOrder}
            setState={setState}
          />
        </BrowserRouter>
      ),
    });
  };

  const copyPoLineItems = () => {
    openModal({
      modalId: "copy",
      title: "Copy Line Items",
      size: "lg",
      children: (
        <BrowserRouter>
          <PurchaseOrderCopyLineItems
            purchaseOrder={purchaseOrder}
            setPurchaseOrder={setPurchaseOrder}
          />
        </BrowserRouter>
      ),
    });
  };

  useEffect(() => {
    getCompanyById();
    getCurrencyOptions();
    getCreditors();
    getTaxSchedules();
    getUsers();
    getNextPoNumber();

    purchaseOrder.currencyID = purchaseOrder.currencyID
      ? purchaseOrder.currencyID
      : parseInt(currencyID);
    purchaseOrder.createdByID = purchaseOrder.createdByID
      ? purchaseOrder.createdByID
      : parseInt(buyerID);

    if (
      purchaseOrder.currencyID > 0 &&
      purchaseOrder.createdByID > 0 &&
      parseInt(nextPoId) > 0
    ) {
      basicInfoForm.setValues({
        currencyID: purchaseOrder
          ? purchaseOrder.currencyID
          : parseInt(currencyID),
        createdByID: purchaseOrder
          ? purchaseOrder.createdByID
          : parseInt(buyerID),
        docNumber:
          basicInfoForm.getInputProps("docNumber").value != ""
            ? basicInfoForm.getInputProps("docNumber").value === "USPUR"
              ? "USPUR" + nextPoId
              : basicInfoForm.getInputProps("docNumber").value
            : "USPUR" + nextPoId,
      });
    } else {
      basicInfoForm.setValues({
        docNumber:
          basicInfoForm.getInputProps("docNumber").value != ""
            ? basicInfoForm.getInputProps("docNumber").value === "USPUR"
              ? "USPUR" + nextPoId
              : basicInfoForm.getInputProps("docNumber").value
            : "USPUR" + nextPoId,
      });
    }

    if (users.length > 0) {
      if (buyerID != "") {
        purchaseOrder.createdByName = users.filter(
          (x) => x.value === buyerID
        )[0].label;
      }
    }

    if (taxSchedules.length > 0) {
      if (purchaseOrder.taxScheduleID != 0) {
        let schedId = purchaseOrder.taxScheduleID
          ? purchaseOrder.taxScheduleID
          : 0;
        purchaseOrder.taxScheduleName = taxSchedules.filter(
          (x) => parseInt(x.value) === schedId
        )[0].label;
      }
    }

    if (currencyOptions.length > 0) {
      if (currencyID != "") {
        purchaseOrder.currencyName = currencyOptions.filter(
          (x) => x.value === currencyID
        )[0].label;
      }
    }
  }, [currencyID, buyerID, nextPoId, purchaseOrder]);

  return (
    <>
      <Box>
        {purchaseOrder.purchaseOrderID && (
          <Menu trigger="hover" openDelay={100} closeDelay={400} shadow="md">
            <Menu.Target>
              <Button>Actions</Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  showEditModal({
                    purchaseOrder,
                    setState: setIsSuccessUpdate,
                  });
                }}
              >
                Edit PO Status
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  copyPoLineItems();
                }}
              >
                Copy PO Lines to Current PO
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Box>
      <Box className="fade-in-block width33">
        <TextInput
          withAsterisk
          label="PO Number"
          placeholder="Enter PO Number"
          disabled
          {...basicInfoForm.getInputProps("docNumber")}
        />
        <NativeSelect
          label="Buyer"
          withAsterisk
          data={users}
          {...basicInfoForm.getInputProps("createdByID")}
          onChange={(event) => {
            purchaseOrder.createdByName = users.filter(
              (x) => x.value === event.currentTarget.value
            )[0].label;
            purchaseOrder.createdByID = parseInt(event.currentTarget.value);

            basicInfoForm.setValues(purchaseOrder);
            setPurchaseOrder(purchaseOrder);
          }}
        />
        <NativeSelect
          label="Creditor"
          withAsterisk
          data={creditors}
          {...basicInfoForm.getInputProps("creditorID")}
          onChange={(event) => {
            purchaseOrder.creditorName = creditors.filter(
              (x) => x.value === event.currentTarget.value
            )[0].label;

            purchaseOrder.creditorID = parseInt(event.currentTarget.value);
            basicInfoForm.setValues(purchaseOrder);
            setPurchaseOrder(purchaseOrder);
          }}
        />
      </Box>
      <Box className="fade-in-block width33">
        <NativeSelect
          label="Tax Schedule ID"
          withAsterisk
          data={taxSchedules}
          {...basicInfoForm.getInputProps("taxScheduleID")}
          onChange={(event) => {
            purchaseOrder.taxScheduleName = taxSchedules.filter(
              (x) => x.value === event.currentTarget.value
            )[0].label;
            purchaseOrder.taxScheduleID = parseInt(event.currentTarget.value);

            basicInfoForm.setValues(purchaseOrder);
            setPurchaseOrder(purchaseOrder);
          }}
        />
        <NativeSelect
          label="Currency"
          withAsterisk
          data={currencyOptions}
          {...basicInfoForm.getInputProps("currencyID")}
          onChange={(event) => {
            purchaseOrder.currencyName = currencyOptions.filter(
              (x) => x.value === event.currentTarget.value
            )[0].label;
            purchaseOrder.currencyID = parseInt(event.currentTarget.value);

            basicInfoForm.setValues(purchaseOrder);
            setPurchaseOrder(purchaseOrder);
          }}
        />
      </Box>
    </>
  );
}
