import {
  Box,
  NativeSelect,
  SimpleGrid,
  TextInput,
  Text,
  Fieldset,
} from "@mantine/core";
import { Dispatch, SetStateAction, useEffect } from "react";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";
import PurchaseOrderLineTable from "./purchaseOrderLineTable";
import { useForm } from "@mantine/form";

export default function PurchaseOrderSummary({
  purchaseOrder,
  setPurchaseOrder,
  lineItems,
  setLineItems,
  lineItemToEdit,
  setLineItemToEdit,
  updatedlineItems,
}: {
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>;
  lineItems: PurchaseOrderLineItem[];
  setLineItems: Dispatch<SetStateAction<PurchaseOrderLineItem[]>>;
  lineItemToEdit?: PurchaseOrderLineItem;
  setLineItemToEdit?: Dispatch<SetStateAction<PurchaseOrderLineItem>>;
  updatedlineItems?: PurchaseOrderLineItem[];
}) {
  useEffect(() => {}, []);
  return (
    <Box>
      <SimpleGrid cols={2}>
        <SimpleGrid style={{ width: "50%", padding: "10px" }} cols={2}>
          <Box style={{ textAlign: "right" }}>
            <Text fw={700}>PO Number</Text>
            <Text fw={700}>Buyer</Text>
            <Text fw={700}>Creditor</Text>
            <Text fw={700}>Tax Schedule</Text>
            <Text fw={700}>Currency</Text>
          </Box>
          <Box style={{ textAlign: "left" }}>
            <Text fw={500}>{purchaseOrder.docNumber}</Text>
            <Text fw={500}>{purchaseOrder.createdByName}</Text>
            <Text fw={500}>{purchaseOrder.creditorName}</Text>
            <Text fw={500}>{purchaseOrder.taxScheduleName}</Text>
            <Text fw={500}>{purchaseOrder.currencyName}</Text>
          </Box>
        </SimpleGrid>

        <PurchaseOrderLineTable
          purchaseOrder={purchaseOrder}
          setPurchaseOrder={setPurchaseOrder}
          lineItems={lineItems}
          setLineItems={setLineItems}
          lineItemToEdit={lineItemToEdit}
          setLineItemToEdit={setLineItemToEdit}
          isSummary={true}
          updatedlineItems={updatedlineItems}
        />
      </SimpleGrid>
    </Box>
  );
}
