import {
  ActionIcon,
  Box,
  Group,
  NumberInput,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";
import commonApi from "../../api/commonApi";

export default function PurchaseOrderLineTable({
  purchaseOrder,
  setPurchaseOrder,
  lineItems,
  setLineItems,
  lineItemToEdit,
  setLineItemToEdit,
  lineItemNo,
  setLineItemNo,
  isSummary,
  updatedlineItems,
  setUpdatedLineItems,
}: {
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>;
  lineItems: PurchaseOrderLineItem[];
  setLineItems: Dispatch<SetStateAction<PurchaseOrderLineItem[]>>;
  lineItemToEdit?: PurchaseOrderLineItem;
  setLineItemToEdit?: Dispatch<SetStateAction<PurchaseOrderLineItem>>;
  lineItemNo?: number;
  setLineItemNo?: Dispatch<SetStateAction<number>>;
  isSummary?: boolean;
  updatedlineItems?: PurchaseOrderLineItem[];
  setUpdatedLineItems?: Dispatch<SetStateAction<PurchaseOrderLineItem[]>>;
}) {
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PurchaseOrderLineItem>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  const getPOLinesById = async () => {
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/lineitems/" + purchaseOrder.purchaseOrderID
      );
      setLineItems(data);
    } catch (error) {}
  };

  useEffect(() => {
    getPOLinesById();
  }, [sortStatus]);

  useEffect(() => {
    if (setUpdatedLineItems)
      if (purchaseOrder.purchaseOrderLines)
        setUpdatedLineItems(purchaseOrder.purchaseOrderLines);
  }, []);

  return (
    <Box>
      <DataTable
        withTableBorder
        borderRadius="sm"
        height="50%"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={updatedlineItems ? updatedlineItems : lineItems}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "lineNumber",
            title: "Line Number",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "itemName",
            title: "Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "itemDescription",
            title: "Description",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "quantity",
            title: "Quantity",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitPrice",
            title: "Unit Cost",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "lineTotal",
            title: "Extended Cost",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            hidden: isSummary,
            render: (lineItem) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (updatedlineItems) {
                      let filteredItems: PurchaseOrderLineItem[] =
                        updatedlineItems.filter(
                          (x: PurchaseOrderLineItem) =>
                            x.itemID != lineItem.itemID
                        );
                      updatedlineItems = filteredItems;
                      if (setLineItemNo) {
                        let newNo = 0;
                        if (lineItemNo) newNo = lineItemNo - 1;
                        setLineItemNo(newNo);
                      }
                      if (setUpdatedLineItems)
                        setUpdatedLineItems(updatedlineItems);

                      //recompute total
                      let poTotal = 0;
                      updatedlineItems.forEach((x) => {
                        let lineTotal: number = x.lineTotal ? x.lineTotal : 0;
                        poTotal = poTotal + lineTotal;
                      });
                      purchaseOrder.total = parseInt(
                        (Math.round(poTotal * 100) / 100).toFixed(2)
                      );
                      purchaseOrder.subTotal = parseInt(
                        (Math.round(poTotal * 100) / 100).toFixed(2)
                      );

                      purchaseOrder.total =
                        purchaseOrder.total +
                        (purchaseOrder.freight ? purchaseOrder.freight : 0) +
                        (purchaseOrder.tradeDiscount
                          ? purchaseOrder.tradeDiscount
                          : 0) +
                        (purchaseOrder.taxAmount
                          ? purchaseOrder.taxAmount
                          : 0) +
                        (purchaseOrder.misc ? purchaseOrder.misc : 0);

                      purchaseOrder.purchaseOrderLines = updatedlineItems;
                      setPurchaseOrder(purchaseOrder);
                    } else {
                      let filteredItems: PurchaseOrderLineItem[] =
                        lineItems.filter(
                          (x: PurchaseOrderLineItem) =>
                            x.itemID != lineItem.itemID
                        );
                      lineItems = filteredItems;
                      if (setLineItemNo) {
                        let newNo = 0;
                        if (lineItemNo) newNo = lineItemNo - 1;
                        setLineItemNo(newNo);
                      }
                      setLineItems(lineItems);

                      //recompute total
                      let poTotal = 0;
                      lineItems.forEach((x) => {
                        let lineTotal: number = x.lineTotal ? x.lineTotal : 0;
                        poTotal = poTotal + lineTotal;
                      });
                      purchaseOrder.total = parseInt(
                        (Math.round(poTotal * 100) / 100).toFixed(2)
                      );
                      purchaseOrder.subTotal = parseInt(
                        (Math.round(poTotal * 100) / 100).toFixed(2)
                      );

                      purchaseOrder.total =
                        purchaseOrder.total +
                        (purchaseOrder.freight ? purchaseOrder.freight : 0) +
                        (purchaseOrder.tradeDiscount
                          ? purchaseOrder.tradeDiscount
                          : 0) +
                        (purchaseOrder.taxAmount
                          ? purchaseOrder.taxAmount
                          : 0) +
                        (purchaseOrder.misc ? purchaseOrder.misc : 0);

                      purchaseOrder.purchaseOrderLines = lineItems;
                      setPurchaseOrder(purchaseOrder);
                    }
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          if (!isSummary) {
            if (setLineItemToEdit) setLineItemToEdit(record);
          }
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <SimpleGrid style={{ display: "inline-grid" }}>
        {/* <Box style={{ textAlign: "right" }}>
            <Text fw={700}>Subtotal</Text>
            <Text fw={700}>Trade Discount</Text>
            <Text fw={700}>Freight</Text>
            <Text fw={700}>Miscellaneous</Text>
            <Text fw={700}>Tax</Text>
            <Text fw={700}>Prepayment</Text>
            <Text fw={700}>Total</Text>
          </Box> */}
        {/* <Box style={{ textAlign: "left" }}>
            <Text fw={500}>{purchaseOrder.subTotal}</Text>
            <Text fw={500}>0.00</Text>
            <Text fw={500}>0.00</Text>
            <Text fw={500}>0.00</Text>
            <Text fw={500}>{purchaseOrder.taxAmount}</Text>
            <Text fw={500}>0.00</Text>
            <Text fw={500}>{purchaseOrder.total}</Text>
          </Box> */}
        <Box>
          <NumberInput
            label="Subtotal"
            disabled
            thousandSeparator=","
            value={purchaseOrder.subTotal}
          />
          <NumberInput
            label="Trade Discount"
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onChange={(value) => {
              value = value.toString() != "" ? parseInt(value.toString()) : 0;

              purchaseOrder.total = purchaseOrder.tradeDiscount
                ? purchaseOrder.total - purchaseOrder.tradeDiscount + value
                : purchaseOrder.total + value;

              purchaseOrder.tradeDiscount = value;
              setPurchaseOrder(purchaseOrder);
            }}
          />
          <NumberInput
            label="Freight"
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onChange={(value) => {
              value = value.toString() != "" ? parseInt(value.toString()) : 0;

              purchaseOrder.total = purchaseOrder.freight
                ? purchaseOrder.total - purchaseOrder.freight + value
                : purchaseOrder.total + value;

              purchaseOrder.freight = value;
              setPurchaseOrder(purchaseOrder);
            }}
          />
          <NumberInput
            label="Miscellaneous"
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onChange={(value) => {
              value = value.toString() != "" ? parseInt(value.toString()) : 0;

              purchaseOrder.total = purchaseOrder.misc
                ? purchaseOrder.total - purchaseOrder.misc + value
                : purchaseOrder.total + value;

              purchaseOrder.misc = value;
              setPurchaseOrder(purchaseOrder);
            }}
          />
          <NumberInput
            label="Tax"
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onChange={(value) => {
              value = value.toString() != "" ? parseInt(value.toString()) : 0;

              purchaseOrder.total = purchaseOrder.taxAmount
                ? purchaseOrder.total - purchaseOrder.taxAmount + value
                : purchaseOrder.total + value;

              purchaseOrder.taxAmount = value;
              setPurchaseOrder(purchaseOrder);
            }}
          />
          <NumberInput
            label="Total"
            disabled
            value={purchaseOrder.total}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
}
