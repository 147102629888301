import { TextInput } from "@mantine/core";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";
import { CustomFormProvider, useCustomForm } from "../../common/customFormContext";
import DropdownInput from "../../common/DropdownInput";

function ItemAdjustmentSearch(props: any) {
  const form = useCustomForm({
    initialValues: {
      search: "",
      itemAdjustmentTypeID: "0",
      siteID: "0"
    },
  });
  return (
    <CustomFormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
        <SearchSection
          buttonSection={
            <ButtonGroup
              cancelLabel="Reset"
              cancelClick={form.reset}
              okLabel="Search"
            />
          }
        >
          <TextInput
            label="Search"
            placeholder="search by detail"
            {...form.getInputProps("search")}
          />
          <DropdownInput
              apiURL={"/inventory/adjustment/types"}
              name={"itemAdjustmentTypeID"}
              label={"Type"}
          />             
          <DropdownInput
              apiURL={"/inventory/site/all"}
              name={"siteID"}
              label={"Site"}
          />                
        </SearchSection>
      </form>
    </CustomFormProvider>
  );
}
export default ItemAdjustmentSearch;