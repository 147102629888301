import {
  ActionIcon,
  Box,
  Group,
  NativeSelect,
  TextInput,
  Select,
} from "@mantine/core";
import { useForm, UseFormReturnType } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import OptionItem from "../../../types/common/optionItem";
import { DateInput, DateInputProps } from "@mantine/dates";
import { IconFile, IconPlus, IconSearch } from "@tabler/icons-react";
import { openModal } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import ReceiveCreateBatch from "./receiveCreateBatch";
import commonApi from "../../../api/commonApi";
import PurchaseOrderLineItem from "../../../types/purchasing/purchaseOrderLineItem";
import ReceivePODetails from "./receivePODetails";
import Receipt from "../../../types/inventory/receipt";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import dayjs from "dayjs";
import ReceiptLine from "../../../types/inventory/receiptLine";

interface receiptFormValues {
  receiptTypeID: number;
  receiptNumber: string;
  currencyName: string;
  currencyID: number;
  creditorID: number;
  creditorName: string;
  creditorDocNumber: string;
  batchID: number;
  docDate: string;
  createdDate: string;
  postingDate: string;
  modifiedDate: string;
  purchaseOrderDocNumber: string;
  purchaseOrderID: number;
}

export default function ReceiveAddForm({
  form,
  receipt,
  setReceipt,
  purchaseOrder,
  setPurchaseOrder,
  isUpdate,
  isLastStep,
}: {
  form: UseFormReturnType<receiptFormValues>;
  receipt?: Receipt;
  setReceipt?: Dispatch<SetStateAction<Receipt>>;
  purchaseOrder?: PurchaseOrder;
  setPurchaseOrder?: Dispatch<SetStateAction<PurchaseOrder>>;
  isUpdate?: boolean;
  isLastStep?: boolean;
}) {
  const [types, setTypes] = useState<OptionItem[]>([]);
  const [receiveDate, setReceiveDate] = useState<Date | null>(null);
  const [params, setParams] = useState({});
  const [poSelections, setPoSelections] = useState<OptionItem[]>([]);
  const [selectedPOid, setSelectedPOid] = useState<string>("0");
  const [lineItems, setLineItems] = useState<PurchaseOrderLineItem[]>([]);
  const [nextReceiptID, setNextReceiptID] = useState("");
  const [receiptTypes, setReceiptTypes] = useState<OptionItem[]>([]);

  const showPODetails = (lineItems: PurchaseOrderLineItem[]) => {
    openModal({
      modalId: "view",
      title: "View Details PO",
      size: "100vw",
      children: (
        <BrowserRouter>
          <ReceivePODetails
            lineItems={lineItems}
            receipt={receipt}
            setReceipt={setReceipt}
          />
        </BrowserRouter>
      ),
    });
  };

  const getReceiptTypes = async () => {
    let data: any[] = [];
    try {
      data = await commonApi.getApiCall("/purchasing/receiptTypes");
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setReceiptTypes(data2);
    }
  };

  const getPurchaseOrderList = async () => {
    let data: any[] = [];
    try {
      data = await commonApi.getApiCall(
        "/purchasing/purchaseorders?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setPoSelections(data2);
    }
  };

  const getPOLinesById = async (poId: number) => {
    try {
      let data: PurchaseOrderLineItem[] = await commonApi.getApiCall(
        "/purchasing/purchaseorders/lineitems/" + poId
      );
      setLineItems(data);
      showPODetails(data);

      if (receipt) {
        if (data.length > 0) {
          receipt.purchaseOrderID = data[0].purchaseOrderID
            ? data[0].purchaseOrderID
            : 0;
          receipt.purchaseOrderDocNumber = data[0].purchaseOrderDocNumber
            ? data[0].purchaseOrderDocNumber
            : "";
          if (setReceipt) setReceipt(receipt);
        }
      }
    } catch (error) {}
  };

  const showBatchModal = () => {
    openModal({
      modalId: "edit",
      title: "Create New Batch",
      size: "lg",
      children: (
        <BrowserRouter>
          <ReceiveCreateBatch />
        </BrowserRouter>
      ),
    });
  };

  const getPOById = async (poId: number) => {
    try {
      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/" + selectedPOid
      );

      if (data) {
        if (setPurchaseOrder) setPurchaseOrder(data);
        form.setFieldValue("currency", data.currencyName);
        form.setFieldValue("creditorName", data.creditorName);
        form.setFieldValue("currencyName", data.company.currency.name);

        if (!isLastStep) {
          let receiptLines: ReceiptLine[] = [];
          if (data?.purchaseOrderLines.length > 0) {
            data?.purchaseOrderLines.forEach((item: PurchaseOrderLineItem) => {
              let receiptLine: ReceiptLine = {
                receiptLineID: 0,
                purchaseOrderDocNumber: item.purchaseOrderDocNumber
                  ? item.purchaseOrderDocNumber
                  : "",
                lineNumber: item.lineNumber ? item.lineNumber : 0,
                quantityOrdered: item.quantity ? item.quantity : 0,
                quantityInvoiced: item.quantity ? item.quantity : 0,
                quantityReceived: item.quantity ? item.quantity : 0,
                unitPrice: item.unitPrice ? item.unitPrice : 0,
                lineTotal: item.lineTotal ? item.lineTotal : 0,
                receiptID: 0,
                receiptDocNumber: "",
                itemID: item.itemID ? item.itemID : 0,
                itemName: item.itemName ? item.itemName : "",
                itemDescription: item.itemDescription
                  ? item.itemDescription
                  : "",
                siteID: item.siteID ? item.siteID : 0,
                siteName: item.siteName ? item.siteName : "",
                unitOfMeasureID: item.unitOfMeasureID
                  ? item.unitOfMeasureID
                  : 0,
                unitOfMeasureName: item.unitOfMeasureName
                  ? item.unitOfMeasureName
                  : "",
                purchaseOrderLineID: item.purchaseOrderLineID
                  ? item.purchaseOrderLineID
                  : 0,
                trackingOptionID: 1,
              };
              receiptLines.push(receiptLine);
            });
          }
          if (receipt) {
            receipt.receiptLines = receiptLines;
            if (setReceipt) setReceipt(receipt);
          }
        }
      }
    } catch (error) {}
  };

  const dateParser: DateInputProps["dateParser"] = (input) => {
    if (input === "WW2") {
      return new Date(1939, 8, 1);
    }

    return dayjs(input, "DD/MM/YYYY").toDate();
  };

  const getNextReceiptNumber = async () => {
    let data = null;

    try {
      data = await commonApi.getApiCall("/purchasing/receipts/nextid");
      setNextReceiptID(data);
      form.setFieldValue("receiptNumber", data);
      if (receipt) receipt.docNumber = data;
    } catch (error) {}
  };

  useEffect(() => {
    getPurchaseOrderList();
    if (receipt?.receiptID) {
      if (receipt?.receiptID > 0) {
        setSelectedPOid(form.getValues().purchaseOrderID.toString());
        setReceiveDate(dayjs(receipt?.createdDate, "DD/MM/YYYY").toDate());
        form.setFieldValue("receiptNumber", receipt.docNumber);
      }
    }

    if (receipt?.receiptID == 0 && !isUpdate) {
      getNextReceiptNumber();
    }
  }, [receipt]);

  useEffect(() => {
    if (parseInt(selectedPOid) > 0) getPOById(parseInt(selectedPOid));
  }, [selectedPOid]);

  useEffect(() => {
    if (receiveDate) {
      form.setFieldValue("docDate", receiveDate ? receiveDate.toString() : "");
    } else {
      if (form.getValues().docDate)
        setReceiveDate(dateParser(form.getValues().docDate.toString()));
    }

    let newReceipt = receipt;
    if (newReceipt) {
      newReceipt.createdDate = receiveDate ? receiveDate : new Date();
      newReceipt.postingDate = receiveDate ? receiveDate : new Date();
    }
  }, [receiveDate]);

  useEffect(() => {
    getReceiptTypes();
  }, []);

  return (
    <>
      <Box className="fade-in-block width33">
        <NativeSelect
          label="Type"
          data={receiptTypes}
          {...form.getInputProps("receiptTypeID")}
          onChange={(event) => {
            let val = event.currentTarget.value;
            form.setFieldValue(
              "receiptTypeID",
              val != null ? parseInt(val) : 0
            );

            if (receipt) {
              receipt.receiptTypeID = val != null ? parseInt(val) : 0;
              if (setReceipt) setReceipt(receipt);
            }
          }}
          disabled={isLastStep ? true : false}
          withAsterisk
        />
        <TextInput
          withAsterisk
          label="Receipt Number"
          placeholder="enter receipt number"
          disabled
          {...form.getInputProps("receiptNumber")}
        />
        <TextInput
          withAsterisk
          label="Currency"
          placeholder="enter currency name"
          disabled
          {...form.getInputProps("currencyName")}
        />
      </Box>
      <Box className="fade-in-block width33">
        <TextInput
          withAsterisk
          label="Creditor"
          disabled
          placeholder="enter creditor name"
          {...form.getInputProps("creditorName")}
        />
        <DateInput
          value={receiveDate}
          onChange={(val) => {
            setReceiveDate(val);
            form.setFieldValue("createdDate", val ? val.toString() : "");
          }}
          label="Date"
          placeholder="receive date"
          dateParser={dateParser}
          valueFormat="MM/DD/YYYY"
          disabled={isLastStep ? true : false}
          withAsterisk
        />
        <Group>
          <NativeSelect
            style={{ width: "85%" }}
            label="BatchID"
            disabled={isLastStep ? true : false}
          />
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            disabled={isLastStep ? true : false}
          >
            <IconSearch />
          </ActionIcon>
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              showBatchModal();
            }}
            disabled={isLastStep ? true : false}
          >
            <IconPlus />
          </ActionIcon>
        </Group>
      </Box>
      <Box className="fade-in-block width33">
        <Group>
          <NativeSelect
            withAsterisk
            label="PO Number"
            style={{ width: "93%" }}
            data={poSelections}
            {...form.getInputProps("purchaseOrderID")}
            onChange={async (event) => {
              let val = event.target.value;
              val !== null ? setSelectedPOid(val) : setSelectedPOid("");

              form.setFieldValue(
                "purchaseOrderDocNumber",
                val != null
                  ? poSelections.filter((x: any) => x.value == parseInt(val))[0]
                      .label
                  : ""
              );

              form.setFieldValue(
                "purchaseOrderID",
                val != null
                  ? parseInt(
                      poSelections.filter(
                        (x: any) => x.value == parseInt(val)
                      )[0].value
                    )
                  : 0
              );

              if (receipt) {
                receipt.purchaseOrderID = parseInt(val !== null ? val : "0");
                if (receipt.receiptLines.length > 0) {
                  if (!isUpdate) {
                    receipt.receiptLines = [];
                  }
                }
              }
              await getPOById(parseInt(val ? val : "'"));
            }}
            disabled={isLastStep || isUpdate ? true : false}
          />
          <ActionIcon
            size="sm"
            variant="subtle"
            style={{ marginTop: "25px" }}
            onClick={async (e: React.MouseEvent) => {
              e.stopPropagation();
              if (selectedPOid == "0") {
                if (receipt) await getPOLinesById(receipt.purchaseOrderID);
              } else {
                await getPOLinesById(parseInt(selectedPOid));
              }
            }}
            disabled={isLastStep ? true : false}
          >
            <IconFile />
          </ActionIcon>
        </Group>
      </Box>
    </>
  );
}
