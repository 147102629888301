import { Box, SimpleGrid, Stepper, Group, Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useForm } from "@mantine/form";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import alerts from "../common/alerts";
import PurchaseBasicForm from "./purchaseBasicForm";
import PurchaseOrderLineForm from "./purchaseOrderLineForm";
import PurchaseOrderLineTable from "./purchaseOrderLineTable";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";
import PurchaseOrderSummary from "./purchaseOrderSummary";

export default function PurchaseOrderEditForm() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { poID } = useParams();
  const [lineItems, setLineItems] = useState<PurchaseOrderLineItem[]>([]);
  const [updatedLineItems, setUpdatedLineItems] = useState<
    PurchaseOrderLineItem[]
  >([]);
  const [poToUpdate, setPoToUpdate] = useState<PurchaseOrder>({
    total: 0,
    subTotal: 0,
    taxAmount: 0,
    freight: 0,
    misc: 0,
    tradeDiscount: 0,
  });
  const [lineItemToEdit, setLineItemToEdit] = useState<PurchaseOrderLineItem>(
    {}
  );

  const [lineItemNo, setLineItemNo] = useState(0);

  const basicInfoForm = useForm({
    initialValues: {
      createdByID: 0,
      creditorID: 0,
      taxScheduleID: 0,
      docNumber: "",
      currencyID: 0,
    },
    validate: {
      docNumber: (value) =>
        value.length < 2 ? "PO number must have at least 2 characters" : null,
      createdByID: (value: number) =>
        value === 0 ? "Please select buyer" : null,
      taxScheduleID: (value: number) =>
        value === 0 ? "Please select tax schedule" : null,
      currencyID: (value: number) =>
        value === 0 ? "Please select currency" : null,
      creditorID: (value: number) =>
        value === 0 ? "Please select creditor" : null,
    },
  });

  const getPOById = async () => {
    try {
      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/" + poID
      );
      basicInfoForm.setValues(data);
      setPoToUpdate(data);
      setLineItems(data.purchaseOrderLines);
    } catch (error) {}
  };

  const getPOLinesById = async () => {
    try {
      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/lineitems/" + poID
      );
      setLineItems(data);
    } catch (error) {}
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      if (currentStep === 0) {
        let creditorID = basicInfoForm.getInputProps("creditorID").value;
        let taxSchedID = basicInfoForm.getInputProps("taxScheduleID").value;
        let currencyID = basicInfoForm.getInputProps("currencyID").value;
        let createdByID = basicInfoForm.getInputProps("createdByID").value;
        let poNumber = basicInfoForm.getInputProps("poNumber").value;
        let docNumber = basicInfoForm.getInputProps("docNumber").value;

        let PO: PurchaseOrder = {
          poNumber: poNumber,
          docNumber: docNumber,
          createdByID: parseInt(createdByID),
          creditorID: parseInt(creditorID),
          taxScheduleID: parseInt(taxSchedID),
          currencyID: parseInt(currencyID),
          statusID: poToUpdate.statusID,
          subLedgerTypeID: 1,
          purchaseOrderID: poID ? parseInt(poID) : 0,
          total: poToUpdate.total,
          subTotal: poToUpdate.subTotal,
          taxAmount: poToUpdate.taxAmount,
          purchaseOrderLines: poToUpdate.purchaseOrderLines,
          freight: poToUpdate.freight,
          misc: poToUpdate.misc,
          tradeDiscount: poToUpdate.tradeDiscount,
          postingDate: poToUpdate.postingDate,
          docDate: poToUpdate.docDate,
        };

        let validationResult = basicInfoForm.validate();
        if (validationResult.hasErrors) {
          basicInfoForm.setErrors(validationResult.errors);
        } else {
          try {
            commonApi
              .putApiCall("/purchasing/purchaseorders/" + poID, PO)
              .then((response) => {
                alerts.showMessage("PO updated");
              });

            setCurrentStep(currentStep + 1);
          } catch (error) {
            alerts.showError(error);
          }
        }
      }

      if (currentStep === 1) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const updatePoHandler = async () => {
    try {
      commonApi
        .putApiCall("/purchasing/purchaseorders/" + poID, poToUpdate)
        .then((response) => {
          navigate("/Finance/Purchasing");
          alerts.showMessage("Purchase updated");
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getPOById();
  }, []);

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Edit PO">
            <PurchaseBasicForm
              basicInfoForm={basicInfoForm}
              purchaseOrder={poToUpdate}
              setPurchaseOrder={setPoToUpdate}
            />
          </Stepper.Step>
          <Stepper.Step label="Select Items">
            <Box>
              <SimpleGrid cols={2}>
                <PurchaseOrderLineForm
                  purchaseOrder={poToUpdate}
                  setPurchaseOrder={setPoToUpdate}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  lineItemToEdit={lineItemToEdit}
                  setLineItemToEdit={setLineItemToEdit}
                  lineItemNo={lineItemNo}
                  setLineItemNo={setLineItemNo}
                  updatedlineItems={updatedLineItems}
                  setUpdatedLineItems={setUpdatedLineItems}
                />
                <PurchaseOrderLineTable
                  purchaseOrder={poToUpdate}
                  setPurchaseOrder={setPoToUpdate}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  lineItemToEdit={lineItemToEdit}
                  setLineItemToEdit={setLineItemToEdit}
                  lineItemNo={lineItemNo}
                  setLineItemNo={setLineItemNo}
                  updatedlineItems={updatedLineItems}
                  setUpdatedLineItems={setUpdatedLineItems}
                />
              </SimpleGrid>
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <Box>
              <PurchaseOrderSummary
                purchaseOrder={poToUpdate}
                setPurchaseOrder={setPoToUpdate}
                lineItems={lineItems}
                setLineItems={setLineItems}
                lineItemToEdit={lineItemToEdit}
                setLineItemToEdit={setLineItemToEdit}
                updatedlineItems={updatedLineItems}
              />
            </Box>
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 2 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 3}>
              Next
            </Button>
          ) : (
            <Button onClick={updatePoHandler}>Update</Button>
          )}

          <Button onClick={() => navigate("/Finance/Purchasing")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
