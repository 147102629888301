import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import IdToken from "../types/idToken";
import { jwtDecode } from "jwt-decode";

const applicationStorage = localStorage;
const addToStorage = (name: string, value: string) => {
  applicationStorage.setItem(name, value);
};

const removeFromStorage = (name: string) => {
  applicationStorage.removeItem(name);
};

const getValueFromStorage = (name: string): string => {
  return applicationStorage.getItem(name) as string;
};

const getClientID = (): string => {
  return process.env.CLIENT_ID as string;
};

const isValidLogin = () => {
  //return true if a userCredential token is present in local storage
  const token = getValueFromStorage("userCredential");
  if (token) {
    return true;
  } else {
    return false;
  }
};

const isTokenExpired = (): boolean => {
  const token = getValueFromStorage("userCredential");
  if (!token) return true; // If no token is present, consider it expired

  try {
    // Decode the JWT to access the payload
    const idToken: IdToken = jwtDecode(token);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    return !idToken.exp || idToken.exp <= currentTimeInSeconds;
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return true; // If decoding fails, consider the token expired
  }
};

const showNotification = (
  title: string,
  msg: string,
  type: NOTIFICATION_TYPE
) => {
  Store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 10000,
      onScreen: false,
      showIcon: true,
      click: false,
    },
  });
};

const Utility = {
  addToStorage,
  removeFromStorage,
  getValueFromStorage,
  getClientID,
  showNotification,
  isValidLogin,
  isTokenExpired,
};

export default Utility;
