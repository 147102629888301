import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import PurchaseOrderSearch from "./purchaseOrderSearch";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import { modals } from "@mantine/modals";
import alerts from "../common/alerts";
import dayjs from "dayjs";

export default function PurchaseOrderList() {
  const navigate = useNavigate();
  const [purchaseOrderList, setPurchaseOrderList] = useState<PurchaseOrder[]>(
    []
  );
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PurchaseOrder>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  const getPurchaseOrderList = async () => {
    let data: any[] = [];
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/purchasing/purchaseorders?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setPurchaseOrderList(data);
    }
  };

  const showDeleteModal = (purchaseOrderID: number) => {
    modals.openConfirmModal({
      title: "Delete purchase order",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this purchase order.
        </Text>
      ),
      labels: { confirm: "Delete purchase order", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("purchasing/purchaseorders/" + purchaseOrderID)
            .then(() => {
              alerts.showMessage("Purchase order deleted");
              getPurchaseOrderList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, [sortStatus, page, pageSize, params]);

  return (
    <div className="fade-in-block width100">
      <PurchaseOrderSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={purchaseOrderList}
        idAccessor="purchaseOrderID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "docNumber",
            title: "PO Number",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "creditorName",
            title: "Creditor Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "createdByName",
            title: "Buyer Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },

          {
            accessor: "createdDate",
            title: "Created Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ createdDate }) =>
              dayjs(createdDate).format("MMM DD YYYY"),
          },
          {
            accessor: "currencyName",
            title: "Currency",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (po) => (
              <Group gap={4} justify="right" wrap="nowrap">
                {/* <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showEditModal(bank.bankID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon> */}
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (po.purchaseOrderID) showDeleteModal(po.purchaseOrderID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Finance/Purchasing/Edit/" + record.purchaseOrderID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
