import dayjs from "dayjs";

function dateMinusTimezoneOffset(date: any){
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}
function dateDisplay(date: any){
    if (date === null){
        return "";
    }
    return dayjs(date).format("MMM DD YYYY")
}
function dateTimeDisplay(date: any){
    if (date === null){
        return "";
    }
    return dayjs(date).format("MMM DD YYYY HH:mm")
}
function decimalDisplay(number: any, hideZero: boolean){
    if (hideZero && number === 0){
        return "-";
    }
    return (Math.round(number * 100) / 100).toFixed(2);;
}
function setFormValueByIndex(
    form: any,
    index: number, 
    propertyName: string, 
    value: string){
    var formValues = form.getValues();
    formValues[index][propertyName] = value;
    form.setValues(formValues);
}
function convertFormValuesToArray(values: any){
    var output = [];
    for (var x = 0; x < Object.keys(values).length; x++){
        output[x] = values[x];
    }
    return output;
}
const extensionMethods = { 
    dateMinusTimezoneOffset,
    dateDisplay,
    dateTimeDisplay,
    decimalDisplay,
    setFormValueByIndex,
    convertFormValuesToArray
};
export default extensionMethods;