import { DataTable, DataTableSortStatus } from "mantine-datatable";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ActionIcon, Box, Group } from "@mantine/core";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import commonApi from "../../../api/commonApi";
import { openModal } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import PurchaseOrder from "../../../types/purchasing/purchaseOrder";
import PurchaseOrderLineItem from "../../../types/purchasing/purchaseOrderLineItem";
import Return from "../../../types/inventory/return";
import ReturnItemsUpdateQuantity from "./returnItemsUpdateQuantity";
import ReturnLine from "../../../types/inventory/returnLine";

export default function ReturnItemsList({
  returns,
  setReturn,
  isLastStep,
}: {
  returns?: Return;
  setReturn?: Dispatch<SetStateAction<Return>>;
  isLastStep?: boolean;
}) {
  const [selectedRecords, setSelectedRecords] = useState<ReturnLine[]>([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ReturnLine>>(
    {
      columnAccessor: "",
      direction: "asc",
    }
  );
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [returnLineItem, setReturnLineItem] = useState<ReturnLine>({
    returnLineID: 0,
    lineNumber: 0,
    quantityReturned: 0,
    quantityReceived: 0,
    unitPrice: 0,
    lineTotal: 0,
    returnID: 0,
    returnDocNumber: "",
    itemID: 0,
    itemName: "",
    itemDescription: "",
    siteID: 0,
    siteName: "",
    unitOfMeasureID: 0,
    unitOfMeasureName: "",
    purchaseOrderDocNumber: "",
    trackingOptionID: 1,
    receiptID: 0,
    receiptLineID: 0,
  });

  const showReturnItemModal = (record: ReturnLine) => {
    openModal({
      modalId: "edit",
      title: "Update Quantity",
      size: "lg",
      children: (
        <BrowserRouter>
          <ReturnItemsUpdateQuantity
            returnLineItem={record}
            setReturnLineItem={setReturnLineItem}
            setState={setIsSuccessUpdate}
          ></ReturnItemsUpdateQuantity>
        </BrowserRouter>
      ),
    });
  };

  return (
    <>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        height="50%"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        minHeight={150}
        records={returns?.returnLines}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        idAccessor="purchaseOrderLineID"
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        columns={[
          {
            accessor: "purchaseOrderDocNumber",
            title: "PO Number",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "itemName",
            title: "Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "itemDescription",
            title: "Description",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitOfMeasureName",
            title: "Unif of Measure",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "siteName",
            title: "Site Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "quantityReturned",
            title: "Quantity Returned",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "quantityReceived",
            title: "Quantity Received",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitPrice",
            title: "Unit Cost",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (returnline) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    let newReturnLines = returns?.returnLines.filter(
                      (x) => x.itemID != returnline.itemID
                    );
                    if (returns?.returnLines)
                      returns.returnLines = newReturnLines
                        ? newReturnLines
                        : [];
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          if (!isLastStep) {
            setReturnLineItem(record);
            if (returnLineItem) showReturnItemModal(record);
          }
        }}
        isRecordSelectable={() => !isLastStep}
      />
    </>
  );
}
