import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import commonApi from "../../../api/commonApi";
import { Box, Group, Button } from "@mantine/core";
import alerts from "../../common/alerts";
import SubLedgerTypeActionMapSourceView from "../../../types/generalLedger/subLedgerTypeActionMapSourceView";
import extensionMethods from "../../../common/extensionMethods";
import OptionItem from "../../../types/common/optionItem";
import { closeAllModals } from "@mantine/modals";

export default function SubLedgerTypeActionMapSourceFormEdit(
    { 
        subLedgerTypeSourceID, 
        subLedgerTypeID
    }: 
    { 
        subLedgerTypeSourceID: number, 
        subLedgerTypeID: number 
    }) {
      const form = useForm();                      
      const [subLedgerTypeActionMapSourceViews, setSubLedgerTypeActionMapSourceViews] = useState<SubLedgerTypeActionMapSourceView[]>([]);      
      const getSubLedgerTypeActionMapSourceViewList = async () => {
        let data = null;
        try {
          data = await commonApi.getApiCall("/SubLedgerType/ActionMap/SourceViews?subLedgerTypeID=" + subLedgerTypeID.toString() + "&subLedgerTypeSourceID=" + subLedgerTypeSourceID.toString());
        } catch (error) {}
        if (data) {
          setSubLedgerTypeActionMapSourceViews(data);
          form.setValues(data);
        }
      };
      const [accounts, setAccounts] = useState<OptionItem[]>([]);
      const getAccountsList = async () => {
        let data = null;
        try {
          data = await commonApi.getApiCall("/accounts");
        } catch (error) {}
        if (data) {
          let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
          data2.splice(0, 0, {label: "-- Select --", value: "0"});
          setAccounts(data2);               
        }
      };      
      const onSubmitHandler = async (values: any) => {
        try {
          await commonApi.putApiCall("/SubLedgerType/ActionMap/Source", extensionMethods.convertFormValuesToArray(values)).then(() => {
            alerts.showMessage("Account Map updated");
            closeAllModals();
          });
        } 
        catch (error) {
          alerts.showError(error);
        }
      };       
      useEffect(() => {
        getAccountsList();        
        getSubLedgerTypeActionMapSourceViewList();
      }, []);
    return (
        <Box mx="auto">
          <form
              onSubmit={form.onSubmit((values) => {
                  onSubmitHandler(values);
          })}>
            <p>This list shows all the account mappings for this sub ledger item in Redux. Each sub ledger item can perform multiple actions, and these actions require an account to be mapped so that the correct transaction is written to the general ledger. Defaults from the company will mean these apply to the sub ledger systems, but can be over-written here.</p>
            <table className="multiRecordEdit">
              <tr>
                <th>Action</th>
                <th>Account</th>
              </tr>
              {subLedgerTypeActionMapSourceViews.map((item, index) => {
                return [
                  <tr>
                    <td>
                      {item.systemSubLedgerTypeActionName}
                    </td>
                    <td>
                      <select onChange={ (e) => extensionMethods.setFormValueByIndex(form, index, "accountID", e.target.value) }>
                        {accounts.map((account, index) => {
                          return[
                            <option value={account.value} selected={account.value == (item.accountID ?? 0).toString()}>{account.label}</option>
                          ];
                        })};
                      </select>
                    </td>
                  </tr>
                ];
              })}
            </table>
            <Group justify="flex-end" mt="md">
                <Button type="submit">Update</Button>
            </Group>              
          </form>          
        </Box>
      );
}