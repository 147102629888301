import { Route, Routes, useParams } from "react-router-dom";

import NotFound from "./notFound";
import Login from "../components/LoginModal";
import Dashboard from "../components/dashboard/dashboard";
import User from "../components/user/user";
import JournalList from "../components/journal/journalList";
import ConnectionError from "./connectionError";
import AccountList from "../components/generalLedger/account/accountList";
import AccountFormEdit from "../components/generalLedger/account/accountFormEdit";
import AccountCategoryList from "../components/generalLedger/accountCategory/accountCategoryList";
import AccountCategoryFormEdit from "../components/generalLedger/accountCategory/accountCategoryFormEdit";
import AccountCategoryTypeList from "../components/generalLedger/accountCategoryType/accountCategoryTypeList";
import AccountCategoryTypeFormEdit from "../components/generalLedger/accountCategoryType/accountCategoryTypeFormEdit";
import CompanyFormEdit from "../components/company/companyFormEdit";
import CompanyGroupCompanyList from "../components/companyGroup/companyGroupCompanyList";
import CompanyGroupUserList from "../components/companyGroup/companyGroupUserList";
import CompanyGroupFormEdit from "../components/companyGroup/companyGroupFormEdit";
import CompanyUserList from "../components/company/companyUserList";

import CompanyUserForm from "../components/company/companyUserForm";
import CompanyUserFormEdit from "../components/company/companyUserFormEdit";
import CompanyGroupCompanyForm from "../components/companyGroup/companyGroupCompanyForm";
import CompanyGroupCompanyFormEdit from "../components/companyGroup/companyGroupCompanyFormEdit";
import CompanyGroupUserForm from "../components/companyGroup/companyGroupUserForm";
import AdminCompanyFormEdit from "../components/admin/adminCompanyFormEdit";
import AdminCompanyList from "../components/admin/adminCompanyList";
import AdminCompanyFormAdd from "../components/admin/adminCompanyFormAdd";
import AdminUserFormAdd from "../components/admin/adminUserFormAdd";
import AdminUserFormEdit from "../components/admin/adminUserFormEdit";
import AdminUserList from "../components/admin/adminUserList";
import AccountCategoryFormAdd from "../components/generalLedger/accountCategory/accountCategoryFormAdd";
import AccountFormAdd from "../components/generalLedger/account/accountFormAdd";
import AccountCategoryTypeFormAdd from "../components/generalLedger/accountCategoryType/accountCategoryTypeFormAdd";
import JournalFormAdd from "../components/journal/journalFormAdd";
import JournalFormEdit from "../components/journal/journalFormEdit";
import JournalDetailFormEdit from "../components/journal/journalDetailFormEdit";
import JournalDetailFormAdd from "../components/journal/journalDetailFormAdd";
import PostingPeriodFormAdd from "../components/generalLedger/postingPeriod/postingPeriodFormAdd";
import PostingPeriodFormEdit from "../components/generalLedger/postingPeriod/postingPeriodFormEdit";
import PostingPeriodList from "../components/generalLedger/postingPeriod/postingPeriodList";
import PostingYearFormAdd from "../components/generalLedger/postingYear/postingYearFormAdd";
import PostingYearFormEdit from "../components/generalLedger/postingYear/postingYearFormEdit";
import PostingYearList from "../components/generalLedger/postingYear/postingYearList";
import CompanyGroupUserFormEdit from "../components/companyGroup/companyGroupUserFormEdit";
import RateDetailList from "../components/tax/rateDetailList";
import RateDetailAdd from "../components/tax/rateDetailAdd";
import RateDetailEdit from "../components/tax/rateDetailEdit";
import ScheduleList from "../components/tax/scheduleList";
import ScheduleAdd from "../components/tax/scheduleAdd";
import ScheduleEdit from "../components/tax/scheduleEdit";
import RoleList from "../components/roles/roleList";
import RoleAdd from "../components/roles/roleAdd";
import RoleEdit from "../components/roles/roleEdit";
import ExchangeRateList from "../components/exchangeRates/exchangeRateList";
import ExchangeRateForm from "../components/exchangeRates/exchangeRateForm";
import ExchangeRateFormEdit from "../components/exchangeRates/exchangeRateFormEdit";
import CurrencyList from "../components/admin/currency/currencyList";
import CurrencyForm from "../components/admin/currency/currencyForm";
import CurrencyFormEdit from "../components/admin/currency/currencyFormEdit";
import LedgerFormPreview from "../components/generalLedger/ledger/ledgerFormPreview";
import LedgerList from "../components/generalLedger/ledger/ledgerList";
import LedgerDetailList from "../components/generalLedger/ledger/ledgerDetailList";
import LedgerFormView from "../components/generalLedger/ledger/ledgerFormView";
import BankList from "../components/currency/bank/bankList";
import BankFormAdd from "../components/currency/bank/bankFormAdd";
import BankFormEdit from "../components/currency/bank/bankFormEdit";
import BankAccountList from "../components/currency/bankAccount/bankAccountList";
import BankAccountFormAdd from "../components/currency/bankAccount/bankAccountFormAdd";
import BankAccountFormEdit from "../components/currency/bankAccount/bankAccountFormEdit";
import ItemList from "../components/inventory/item/itemList";
import ItemFormAdd from "../components/inventory/item/itemFormAdd";
import ItemFormEdit from "../components/inventory/item/itemFormEdit";
import CreditorList from "../components/creditor/creditorList";
import CreditorAddForm from "../components/creditor/creditorAddForm";
import SiteList from "../components/inventory/sites/siteList";
import SiteFormAdd from "../components/inventory/sites/siteFormAdd";
import SiteFormEdit from "../components/inventory/sites/siteFormEdit";
import CreditorEditForm from "../components/creditor/creditorEditForm";
import PurchaseOrderList from "../components/purchasing/purchaseList";
import PurchaseOrderAddForm from "../components/purchasing/purchaseAddForm";
import PurchaseOrderEditForm from "../components/purchasing/purchaseEditForm";
import SubLedgerTypeActionMapCompanyList from "../components/generalLedger/subLedgerTypeActionMap/subLedgerTypeActionMapCompanyList";
import ReturnsAdd from "../components/inventory/returns/returnsAdd";
import ReceiveAdd from "../components/inventory/receive/receiveAdd";
import ReceiveList from "../components/inventory/receive/receiveList";
import ReceiveEdit from "../components/inventory/receive/receiveEdit";
import ReturnsList from "../components/inventory/returns/returnsList";
import ReturnEdit from "../components/inventory/returns/returnsEdit";
import ItemAdjustmentList from "../components/inventory/itemAdjustment/itemAdjustmentList";
import ItemAdjustmentFormEdit from "../components/inventory/itemAdjustment/itemAdjustmentFormEdit";
import ItemAdjustmentFormAdd from "../components/inventory/itemAdjustment/itemAdjustmentFormAdd";

const RouteSwitcher = () => {
  return (
    <Routes>
      {/* <ReactNotifications /> */}
      <Route path="/" element={<Dashboard />} />
      <Route path="/user" element={<User />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/companygroup/company/list"
        element={<CompanyGroupCompanyList />}
      />
      <Route
        path="/companygroup/user/list"
        element={<CompanyGroupUserList />}
      />
      <Route
        path="/companygroup/company/add"
        element={<CompanyGroupCompanyForm />}
      />
      <Route
        path="/companygroup/company/edit/:compID"
        element={<CompanyGroupCompanyFormEdit />}
      />
      <Route path="/companygroup/user/add" element={<CompanyGroupUserForm />} />
      <Route
        path="/companygroup/user/edit/:userID/"
        element={<CompanyGroupUserFormEdit />}
      />
      <Route path="/companygroup/edit" element={<CompanyGroupFormEdit />} />

      <Route path="/user/list" element={<CompanyUserList />} />
      <Route path="/user" element={<User />} />
      <Route path="/login" element={<Login />} />
      <Route path="/connectionError" element={<ConnectionError />} />

      <Route path="/company/users/add" element={<CompanyUserForm />} />
      <Route
        path="/company/users/edit/:userID/"
        element={<CompanyUserFormEdit />}
      />
      <Route path="/company" element={<CompanyFormEdit />} />

      <Route path="/journal/list" element={<JournalList />} />
      <Route path="/journal/add" element={<JournalFormAdd />} />
      <Route path="/journal/edit/:journalID" element={<JournalFormEdit />} />
      <Route
        path="/journaldetail/edit/:journalDetailID"
        element={<JournalDetailFormEdit />}
      />
      <Route
        path="/journaldetail/add/:journalID"
        element={<JournalDetailFormAdd />}
      />

      <Route path="/account/list" element={<AccountList />} />
      <Route path="/account/edit/:accountID" element={<AccountFormEdit />} />
      <Route path="/account/add" element={<AccountFormAdd />} />

      <Route path="/account/category/list" element={<AccountCategoryList />} />
      <Route
        path="/account/category/edit/:accountCategoryID"
        element={<AccountCategoryFormEdit />}
      />
      <Route
        path="/account/category/add"
        element={<AccountCategoryFormAdd />}
      />

      <Route
        path="/account/category/type/list"
        element={<AccountCategoryTypeList />}
      />
      <Route
        path="/account/category/type/edit/:accountCategoryTypeID"
        element={<AccountCategoryTypeFormEdit />}
      />
      <Route
        path="/account/category/type/add"
        element={<AccountCategoryTypeFormAdd />}
      />

      <Route path="/postingYear/list" element={<PostingYearList />} />
      <Route
        path="/postingYear/edit/:postingYearID"
        element={<PostingYearFormEdit />}
      />
      <Route path="/postingYear/add" element={<PostingYearFormAdd />} />

      <Route path="/postingPeriod/list" element={<PostingPeriodList />} />
      <Route
        path="/postingPeriod/edit/:postingPeriodID"
        element={<PostingPeriodFormEdit />}
      />
      <Route path="/postingPeriod/add" element={<PostingPeriodFormAdd />} />

      <Route path="/admin/company/list" element={<AdminCompanyList />} />
      <Route
        path="/admin/company/edit/:companyID"
        element={<AdminCompanyFormEdit />}
      />
      <Route path="/admin/company/add" element={<AdminCompanyFormAdd />} />

      <Route path="/admin/user/list" element={<AdminUserList />} />
      <Route path="/admin/user/edit/:userID" element={<AdminUserFormEdit />} />
      <Route path="/admin/user/add" element={<AdminUserFormAdd />} />

      <Route path="/tax/ratedetail/list" element={<RateDetailList />} />
      <Route path="/tax/ratedetail/add" element={<RateDetailAdd />} />
      <Route
        path="/tax/ratedetail/edit/:rateDetailId"
        element={<RateDetailEdit />}
      />

      <Route path="/tax/schedule/list" element={<ScheduleList />} />
      <Route path="/tax/schedule/add" element={<ScheduleAdd />} />
      <Route path="/tax/schedule/edit/:scheduleId" element={<ScheduleEdit />} />

      <Route path="/role/list" element={<RoleList />} />
      <Route path="/role/add" element={<RoleAdd />} />
      <Route path="/role/edit/:roleId" element={<RoleEdit />} />

      <Route path="/exchangerate/list" element={<ExchangeRateList />} />
      <Route path="/exchangerate/add" element={<ExchangeRateForm />} />
      <Route
        path="/exchangerate/edit/:exchangeRateId"
        element={<ExchangeRateFormEdit />}
      />

      <Route path="/admin/currency/list" element={<CurrencyList />} />
      <Route path="/admin/currency/add" element={<CurrencyForm />} />
      <Route
        path="/admin/currency/edit/:systemCurrencyID"
        element={<CurrencyFormEdit />}
      />

      <Route path="/ledger/list" element={<LedgerList />} />
      <Route
        path="/ledger/preview/:subLedgerTypeSourceID/:subLedgerTypeID"
        element={<LedgerFormPreview />}
      />
      <Route path="/ledger/view/:ledgerID" element={<LedgerFormView />} />
      <Route path="/ledgerdetail/view/:ledgerID" element={<LedgerFormView />} />

      <Route path="/ledgerdetail/list" element={<LedgerDetailList />} />

      <Route path="/bank/list" element={<BankList />} />
      <Route path="/bank/add" element={<BankFormAdd />} />
      <Route path="/bank/edit/:bankId" element={<BankFormEdit />} />

      <Route path="/bankAccount/list" element={<BankAccountList />} />
      <Route path="/bankAccount/add" element={<BankAccountFormAdd />} />
      <Route
        path="/bankAccount/edit/:bankAccountId"
        element={<BankAccountFormEdit />}
      />

      <Route path="/inventory/item/list" element={<ItemList />} />
      <Route path="/inventory/item/add" element={<ItemFormAdd />} />
      <Route path="/inventory/item/edit/:itemID" element={<ItemFormEdit />} />
      <Route path="/inventory/adjustment/list" element={<ItemAdjustmentList />} />
      <Route path="/inventory/adjustment/add" element={<ItemAdjustmentFormAdd />} />
      <Route path="/inventory/adjustment/edit/:itemAdjustmentID" element={<ItemAdjustmentFormEdit />} />
      

      <Route path="/finance/creditor/list" element={<CreditorList />} />
      <Route path="/finance/creditor/add" element={<CreditorAddForm />} />
      <Route
        path="/finance/creditor/edit/:creditorId"
        element={<CreditorEditForm />}
      />
      <Route path="/finance/purchasing" element={<PurchaseOrderList />} />
      <Route
        path="/finance/purchasing/add"
        element={<PurchaseOrderAddForm />}
      />

      <Route
        path="/finance/purchasing/edit/:poID"
        element={<PurchaseOrderEditForm />}
      />

      <Route path="/inventory/site/list" element={<SiteList />} />
      <Route path="/inventory/site/add" element={<SiteFormAdd />} />
      <Route path="/inventory/site/edit/:siteID" element={<SiteFormEdit />} />

      <Route path="/inventory/returns/add" element={<ReturnsAdd />} />
      <Route path="/inventory/returns/list" element={<ReturnsList />} />
      <Route
        path="/inventory/returns/edit/:returnID"
        element={<ReturnEdit />}
      />

      <Route path="/inventory/receive/list" element={<ReceiveList />} />
      <Route path="/inventory/receive/add" element={<ReceiveAdd />} />
      <Route
        path="/inventory/receive/edit/:receiptID"
        element={<ReceiveEdit />}
      />

      <Route
        path="/subledgertype/actionmap/company/list"
        element={<SubLedgerTypeActionMapCompanyList />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteSwitcher;
