import { Box, Button, Group, NativeSelect } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import OptionItem from "../../types/common/optionItem";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { IconMoodConfuzed } from "@tabler/icons-react";
import PurchaseOrderLineItem from "../../types/purchasing/purchaseOrderLineItem";
import { closeAllModals } from "@mantine/modals";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";

export default function PurchaseOrderCopyLineItems({
  purchaseOrder,
  setPurchaseOrder,
}: {
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>;
}) {
  const [poSelections, setPoSelections] = useState<OptionItem[]>([]);
  const [lineItems, setLineItems] = useState<PurchaseOrderLineItem[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [selectedRecords, setSelectedRecords] = useState<
    PurchaseOrderLineItem[]
  >([]);
  const [params, setParams] = useState({});

  const [totalRecords, setTotalRecords] = useState(1);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PurchaseOrderLineItem>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  const getPurchaseOrderList = async () => {
    let data: any[] = [];
    try {
      data = await commonApi.getApiCall(
        "/purchasing/purchaseorders?" + new URLSearchParams(params).toString()
      );

      data = data.filter(
        (x: PurchaseOrder) => x.purchaseOrderID != purchaseOrder.purchaseOrderID
      );
    } catch (error) {}
    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setPoSelections(data2);
    }
  };

  const getPOLinesById = async (purchaseOrderId: number) => {
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);

      let data = await commonApi.getApiCall(
        "/purchasing/purchaseorders/lineitems/" + purchaseOrderId
      );
      setLineItems(data);
    } catch (error) {}
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, []);

  useEffect(() => {
    let newAmount = 0;
    purchaseOrder.purchaseOrderLines?.forEach((item: PurchaseOrderLineItem) => {
      newAmount = newAmount + (item.lineTotal ? item.lineTotal : 0);
    });
    purchaseOrder.total = newAmount;
    setPurchaseOrder(purchaseOrder);
  }, [totalAmount]);
  return (
    <>
      <Box>
        <Box>
          <NativeSelect
            label="Purchase Order"
            withAsterisk
            data={poSelections}
            style={{ paddingBottom: "10px" }}
            onChange={(event) => {
              getPOLinesById(parseInt(event.target.value));
            }}
          />
        </Box>
        <Box>
          <PaginationSection
            page={page}
            setPageFunction={setPage}
            pageSize={pageSize}
            setPageSizeFunction={setPageSize}
            recordCount={totalRecords}
          />
          <DataTable
            withTableBorder
            borderRadius="sm"
            height="50%"
            withColumnBorders
            striped
            highlightOnHover
            textSelectionDisabled
            minHeight={150}
            records={lineItems}
            noRecordsIcon={
              <Box p={4} mb={4}>
                <IconMoodConfuzed size={36} strokeWidth={1.5} />
              </Box>
            }
            noRecordsText="No records found"
            idAccessor="purchaseOrderLineID"
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            isRecordSelectable={(record) => {
              let selectable = true;
              if (record.itemID) {
                if (record.itemID > 0) selectable = true;
              }
              return selectable;
            }}
            columns={[
              {
                accessor: "itemName",
                title: "Name",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "itemDescription",
                title: "Description",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "quantity",
                title: "Quantity",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "unitPrice",
                title: "Unit Cost",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
            ]}
          />

          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                closeAllModals();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                let lineItems = purchaseOrder.purchaseOrderLines;
                if (lineItems) {
                  if (selectedRecords.length > 0) {
                    let lineItemCounter = lineItems.length;
                    selectedRecords.forEach((item: PurchaseOrderLineItem) => {
                      //check if existing
                      let toEditItem: PurchaseOrderLineItem[] = [];
                      if (lineItems) {
                        let toEditItem = lineItems.filter(
                          (x) => x.itemID === item.itemID
                        );
                      }

                      if (toEditItem.length > 0) {
                        let editItem = toEditItem[0];
                        if (editItem.quantity && item.quantity) {
                          editItem.quantity = editItem.quantity + item.quantity;
                          editItem.lineTotal =
                            editItem.quantity *
                            (editItem.unitPrice ? editItem.unitPrice : 0);
                        }
                      } else {
                        lineItemCounter = lineItemCounter + 1;
                        item.lineNumber = lineItemCounter;
                        if (lineItems) lineItems.push(item);
                      }

                      setTotalAmount(item.lineTotal ? item.lineTotal : 0);
                    });
                  }
                }
                purchaseOrder.purchaseOrderLines = lineItems;
                setPurchaseOrder(purchaseOrder);
                closeAllModals();
              }}
            >
              Copy
            </Button>
          </Group>
        </Box>
      </Box>
    </>
  );
}
