import { Box, Button, Group, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction } from "react";
import { closeAllModals } from "@mantine/modals";
import ReturnLine from "../../../types/inventory/returnLine";

export default function ReturnItemsUpdateQuantity({
  returnLineItem,
  setReturnLineItem,
  setState,
}: {
  returnLineItem: ReturnLine;
  setReturnLineItem: Dispatch<SetStateAction<ReturnLine>>;
  setState: Dispatch<SetStateAction<boolean>>;
}) {
  let maxNumber = returnLineItem.quantityReceived;
  const lineItemForm = useForm({
    initialValues: {
      returnLineID: returnLineItem.returnLineID,
      lineNumber: returnLineItem.lineNumber,
      quantityReturned: returnLineItem.quantityReturned,
      quantityReceived: returnLineItem.quantityReceived,
      unitPrice: returnLineItem.unitPrice,
      lineTotal: returnLineItem.lineTotal,
      returnID: returnLineItem.returnID,
      returnDocNumber: returnLineItem.returnDocNumber,
      itemID: returnLineItem.itemID,
      itemName: returnLineItem.itemName,
      itemDescription: returnLineItem.itemDescription,
      siteID: returnLineItem.siteID,
      siteName: returnLineItem.siteName,
      unitOfMeasureID: returnLineItem.unitOfMeasureID,
      unitOfMeasureName: returnLineItem.unitOfMeasureName,
      purchaseOrderDocNumber: returnLineItem.purchaseOrderDocNumber,
      trackingOptionID: returnLineItem.trackingOptionID,
    },
  });

  const updateHandler = () => {
    returnLineItem.quantityReturned = lineItemForm.getValues().quantityReturned;
    setReturnLineItem(returnLineItem);
    closeAllModals();
  };

  return (
    <>
      <Box className="fade-in-block width66">
        <TextInput
          label="Item Name"
          disabled
          {...lineItemForm.getInputProps("itemName")}
        />
        <TextInput
          label="Item Description"
          disabled
          {...lineItemForm.getInputProps("itemDescription")}
        />
        <NumberInput
          label="Quantity Received"
          placeholder="Enter quantity"
          allowNegative={false}
          disabled
          {...lineItemForm.getInputProps("quantityReceived")}
        />
        <NumberInput
          label="Quantity Returned"
          placeholder="Enter quantity"
          allowNegative={false}
          max={maxNumber}
          {...lineItemForm.getInputProps("quantityReturned")}
        />
      </Box>
      <Group justify="flex-end" mt="md">
        <Button
          onClick={(event) => {
            closeAllModals();
          }}
        >
          Cancel
        </Button>
        <Button onClick={updateHandler}>Update</Button>
      </Group>
    </>
  );
}
