import { Box, Button, Group } from "@mantine/core";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import { Dispatch, SetStateAction, useState } from "react";
import commonApi from "../../../api/commonApi";
import PurchaseOrderLineItem from "../../../types/purchasing/purchaseOrderLineItem";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { IconMoodConfuzed } from "@tabler/icons-react";
import { closeAllModals } from "@mantine/modals";
import Receipt from "../../../types/inventory/receipt";
import ReceiptLine from "../../../types/inventory/receiptLine";

export default function ReceivePODetails({
  lineItems,
  receipt,
  setReceipt,
}: {
  lineItems: PurchaseOrderLineItem[];
  receipt?: Receipt;
  setReceipt?: Dispatch<SetStateAction<Receipt>>;
}) {
  const [selectedRecords, setSelectedRecords] = useState<
    PurchaseOrderLineItem[]
  >([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PurchaseOrderLineItem>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  return (
    <>
      <Box>
        <Box>
          <PaginationSection
            page={page}
            setPageFunction={setPage}
            pageSize={pageSize}
            setPageSizeFunction={setPageSize}
            recordCount={totalRecords}
          />
          <DataTable
            withTableBorder
            borderRadius="sm"
            height="50%"
            withColumnBorders
            striped
            highlightOnHover
            textSelectionDisabled
            minHeight={150}
            records={lineItems}
            noRecordsIcon={
              <Box p={4} mb={4}>
                <IconMoodConfuzed size={36} strokeWidth={1.5} />
              </Box>
            }
            noRecordsText="No records found"
            idAccessor="purchaseOrderLineID"
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            columns={[
              {
                accessor: "purchaseOrderDocNumber",
                title: "PO Number",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "itemName",
                title: "Name",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "itemDescription",
                title: "Description",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "unitOfMeasureName",
                title: "Unif of Measure",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "siteName",
                title: "Site Name",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "quantity",
                title: "Quantity Shipped",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "quantity",
                title: "Quantity Ordered",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "quantity",
                title: "Quantity Invoiced",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
              {
                accessor: "unitPrice",
                title: "Unit Cost",
                textAlign: "left",
                sortable: true,
                visibleMediaQuery: (theme) =>
                  `(min-width: ${theme.breakpoints.md})`,
              },
            ]}
          />

          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                closeAllModals();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();

                let receiptLines: ReceiptLine[] = [];
                if (lineItems) {
                  if (selectedRecords.length > 0) {
                    selectedRecords.forEach((item: PurchaseOrderLineItem) => {
                      let receiptLine: ReceiptLine = {
                        receiptLineID: 0,
                        purchaseOrderDocNumber: item.purchaseOrderDocNumber
                          ? item.purchaseOrderDocNumber
                          : "",
                        lineNumber: item.lineNumber ? item.lineNumber : 0,
                        quantityOrdered: item.quantity ? item.quantity : 0,
                        quantityInvoiced: item.quantity ? item.quantity : 0,
                        quantityReceived: item.quantity ? item.quantity : 0,
                        unitPrice: item.unitPrice ? item.unitPrice : 0,
                        lineTotal: item.lineTotal ? item.lineTotal : 0,
                        receiptID: 0,
                        receiptDocNumber: "",
                        itemID: item.itemID ? item.itemID : 0,
                        itemName: item.itemName ? item.itemName : "",
                        itemDescription: item.itemDescription
                          ? item.itemDescription
                          : "",
                        siteID: item.siteID ? item.siteID : 0,
                        siteName: item.siteName ? item.siteName : "",
                        unitOfMeasureID: item.unitOfMeasureID
                          ? item.unitOfMeasureID
                          : 0,
                        unitOfMeasureName: item.unitOfMeasureName
                          ? item.unitOfMeasureName
                          : "",
                        purchaseOrderLineID: item.purchaseOrderLineID
                          ? item.purchaseOrderLineID
                          : 0,
                        trackingOptionID: 1,
                      };
                      receiptLines.push(receiptLine);
                    });
                  }
                }
                if (receipt) {
                  receipt.receiptLines = receiptLines;
                  if (setReceipt) setReceipt(receipt);
                }
                closeAllModals();
              }}
            >
              Receive
            </Button>
          </Group>
        </Box>
      </Box>
    </>
  );
}
