import { NativeSelect } from "@mantine/core";
import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import OptionItem from "../../types/common/optionItem";
import { useCustomFormContext } from "./customFormContext";

interface DropdownInputProps {
  apiURL: string;
  name: string;
  label: string;
  description?: string;
  firstValueNull?: boolean;
}
/**
 * Insert a dropdown with the specified data from a URL. A form context is required to use this dropdown, you can use 
  customFormContext.
 *
 * @param {string} apiURL the url to get the dropdown data
 * @param {string} name the name of the input
 * @param {string} label the label of the input
 * @param {string|undefined} description a text below the label to describe the field, this parameter is optional
 * @param {boolean|true} firstValueNull set true to indicate the first value will be -- Select -- instead of the first value of the dataa array
 * @requires customFormContext or any formContext you want to create.
 * @see Check Mantine form context documentation here: https://mantine.dev/form/create-form-context/
 */
export default function DropdownInput({
  apiURL,
  name,
  label,
  description = "",
  firstValueNull = true,
}: DropdownInputProps) {
  const [dropdownMethod, setDropdownMethod] = useState<OptionItem[]>([]);
  useEffect(() => {
    getDropdownMethodList();
  }, []);

  const form = useCustomFormContext();

  const getDropdownMethodList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall(apiURL);
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      firstValueNull &&
        data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setDropdownMethod(data2);
    }
  };

  return (
    <NativeSelect
      name={name}
      label={label}
      data={dropdownMethod}
      description={description}
      {...form.getInputProps(name)}
    />
  );
}
