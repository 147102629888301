import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PurchaseOrder from "../../types/purchasing/purchaseOrder";
import { Box, Button, Group, NativeSelect, TextInput } from "@mantine/core";
import { DateInput, DateInputProps } from "@mantine/dates";
import { useForm } from "@mantine/form";
import commonApi from "../../api/commonApi";
import OptionItem from "../../types/common/optionItem";
import alerts from "../common/alerts";
import { useNavigate } from "react-router-dom";
import { closeAllModals } from "@mantine/modals";
import dayjs from "dayjs";

export default function PurchaseOrderStatusUpdate({
  purchaseOrder,
  setState,
}: {
  purchaseOrder: PurchaseOrder;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const [postingDate, setPostingDate] = useState<Date | null>(null);
  const [docDate, setDocDate] = useState<Date | null>(null);
  const [poStatusList, setPoStatusList] = useState<OptionItem[]>([]);
  const [typeList, setTypeList] = useState<OptionItem[]>([]);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      type: 0,
      postingDate: purchaseOrder.postingDate,
      docDate: purchaseOrder.docDate,
      poNumber: purchaseOrder.docNumber,
      createdByID: purchaseOrder.createdByID,
      createdByName: purchaseOrder.createdByName,
      createdDate: purchaseOrder.createdDate,
      poStatus: purchaseOrder.statusID?.toString(),
    },
    validate: {
      // poNumber: (value) =>
      //   value.length < 1 ? "PO number must have at least 2 characters" : null,
      // poStatus: (value: number) =>
      //   value === 0 ? "Please select PO status" : null,
      // type: (value: number) =>
      //   value === 0 ? "Please select type value" : null,
    },
  });

  const getPOStatusList = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/purchasing/purchaseorderstatus");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setPoStatusList(data2);
    }
  };

  const updatePoHandler = async () => {
    let poToUpdate = purchaseOrder;
    poToUpdate.postingDate = postingDate?.toISOString();
    poToUpdate.docDate = docDate?.toISOString();
    if (form.values.poStatus)
      poToUpdate.statusID = parseInt(form.values.poStatus);

    try {
      commonApi
        .putApiCall(
          "/purchasing/purchaseorders/" + purchaseOrder.purchaseOrderID,
          poToUpdate
        )
        .then((response) => {
          closeAllModals();
          alerts.showMessage("Purchase updated");
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const dateParser: DateInputProps["dateParser"] = (input) => {
    if (input === "WW2") {
      return new Date(1939, 8, 1);
    }

    return dayjs(input, "DD/MM/YYYY").toDate();
  };

  useEffect(() => {
    getPOStatusList();
    if (purchaseOrder.postingDate) {
      setPostingDate(dayjs(purchaseOrder.postingDate, "DD/MM/YYYY").toDate());
    }

    if (purchaseOrder.docDate) {
      setDocDate(dayjs(purchaseOrder.docDate, "DD/MM/YYYY").toDate());
    }
  }, [purchaseOrder]);

  return (
    <>
      <Box className="fade-in-block width50">
        <TextInput
          label="PO Number"
          disabled
          {...form.getInputProps("poNumber")}
        />
        <TextInput label="Type" disabled {...form.getInputProps("name")} />
        <NativeSelect
          label="Purchase Order Status"
          withAsterisk
          data={poStatusList}
          {...form.getInputProps("poStatus")}
        />
      </Box>
      <Box className="fade-in-block width50">
        <DateInput
          value={postingDate}
          onChange={setPostingDate}
          label="Posting Date"
          placeholder="posting date"
          dateParser={dateParser}
          valueFormat="MM/DD/YYYY"
        />
        <DateInput
          value={docDate}
          onChange={setDocDate}
          label="Document Date"
          placeholder="document date"
          dateParser={dateParser}
          valueFormat="MM/DD/YYYY"
        />
        <TextInput
          label="Created By"
          disabled
          {...form.getInputProps("createdByName")}
        />
      </Box>
      <Group justify="flex-end" mt="md">
        <Button
          onClick={(event) => {
            closeAllModals();
          }}
        >
          Cancel
        </Button>
        <Button onClick={updatePoHandler}>Update</Button>
      </Group>
    </>
  );
}
